import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Grid,
  Typography,
  Box,
  Checkbox,
  Rating,
  Tabs,
  Tab,
} from '@mui/material';
import { images } from '../../Assets/images';
import { icons } from '../../Assets/icons';
import { useNavigate } from 'react-router-dom';
import './Shop.css';
import toast from 'react-hot-toast';
import About from './About/about';
import Slider from 'react-slick';
import AcademyVideos from './Videos/videos';
import NearbySalon from './NearbySalon/NearbySalon';
import Sidebar from './Sidebar/Sidebar';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OpeningTimes from './OpeningTimes/OpeningTimes';
import Favorite from '@mui/icons-material/Favorite';
import Reviews from './Review/Review';
import AppointmentBooking from './Sidebar/AppointmentBooking';
import Membership from './MemberShip/Membership';
import { styled, width } from '@mui/system';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Dialog from '@mui/material/Dialog';
import { Link } from 'react-router-dom';
import {
  vendorDetailsResponse,
  VendorDetailData,
  VendorMedia,
  PortfolioItem,
} from '../../../src/api/types/shop';
// Context
import useVendorDetailsContext from '../../../src/hooks/useVendorDetailsContext';
// API
import { ShopAPI } from '../../../src/hooks/shop';
import { ApiResponse } from '../../../src/api/types/common';
import ViewPackageDetailsPopups from './ViewPackageDetailsPopups';
import { Service } from '../../../src/api/types/services';
import CallIcon from '@mui/icons-material/Call';
import TeamMember from './Team/team';
import {
  vendorServicesObject,
  vendorServicesReqInterface,
} from '../../api/types/vendor_services';
import { vendorServicesResInterface } from '../../api/types/vendor_services';

const Settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  centerMode: false,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1600,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 1366,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 1280,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 1200,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 1024,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 900,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        autoplay: false,
        arrows: false,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        autoplay: false,
        arrows: false,
        dots: true,
      },
    },
  ],
};

interface VendorDetail {
  data?: VendorData; // Optional in case it can be undefined
}

interface VendorData {
  subscription_plan_id: string;
}

interface Vendor {
  vendor_detail?: VendorDetail; // Optional in case it can be undefined
}

interface DataItem {
  id: string;
  name: string;
  type: string;
  icon: string;
  social_url: string;
}

const Shop: React.FC = () => {
  // API

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide loader after 5 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [isLoading]);

  useEffect(() => {
    localStorage.removeItem('shop_category_id');
    localStorage.removeItem('bookFromSidebar');
    localStorage.removeItem('selected_services');
    localStorage.removeItem('appointMentJourney');
  }, []);

  const { addRemoveFavVendorAPICall, getVendorServicesAPI } = ShopAPI();
  // Use Context
  const { getVendorDetailsFunc } = useVendorDetailsContext();
  // STATES
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [servicesDataOfVendor, setServicesDataOfVendor] = useState<
    vendorServicesObject[] | null
  >(null);

  // Get Services
  const vendorServices = useCallback(
    async (vendor_detail_id: number) => {
      const requestData: vendorServicesReqInterface = {
        vendor_detail_id: vendor_detail_id,
      };
      const result: vendorServicesResInterface | null =
        await getVendorServicesAPI(requestData);
      if (result === null) {
        toast.error('Failed to fetch vendor services data.');
        return; // Exit the function early if the result is null
      }
      if (result?.status_code === 200) {
        setServicesDataOfVendor(result.data);
      } else {
        toast.error(result?.message);
      }
    },
    [getVendorServicesAPI],
  );

  const [currentShopID, setCurrentShopID] = useState(
    localStorage.getItem('shopID')
      ? Number(localStorage.getItem('shopID'))
      : null,
  );
  const [shopDetails, setShopDetails] = useState(null);
  const [isServicesAvail, setIsServicesAvail] = useState<boolean>(false);

  const handleNearbyShopClick = (newShopID: any) => {
    if (newShopID !== currentShopID) {
      setCurrentShopID(newShopID); // Update state without reloading
      localStorage.setItem('shopID', newShopID); // Optional: Keep in sync with localStorage
      window.location.reload();
    }
  };

  useEffect(() => {
    // Fetch shop details based on currentShopID
    fetchShopDetails(currentShopID);
    if (currentShopID !== null) {
      vendorServices(currentShopID);
    }
  }, [currentShopID]);

  const fetchShopDetails = async (shopID: any) => {
    // Fetch logic for shop details (API or local data)
    setShopDetails(shopID);
  };
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openPhoto, setOpenPhoto] = useState(false);
  const [open, setOpen] = React.useState(false);

  const viewMoreServices = () => {
    setOpen(true);
  };

  const handleClickOpen = (category_id: string, service_id: any) => {
    const normalizedServiceObject = {
      ...service_id,
      charges: service_id?.charges === '' ? '0' : service_id?.charges,
    };
    localStorage.removeItem('shop_category_id');
    localStorage.removeItem('shop_service_id');
    localStorage.setItem(
      'shop_category_id',
      activeService === '' ? '' : category_id,
    );
    localStorage.setItem(
      'shop_service_id',
      JSON.stringify(normalizedServiceObject),
    );
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  document.querySelectorAll('.shopMainDiv .tabs button').forEach(button => {
    if (button.textContent) {
      if (button.textContent.trim() === 'Packages') {
        button.classList.add('packageService');
      }
    }
  });

  const [vendorData, setVendorData] = useState<VendorDetailData | null>();
  const [activeService, setActiveService] = useState<string>('0');
  const [latLong, setLatLong] = useState<any>({
    lat: 23.027181141518188,
    lng: 72.48185665364666,
  });
  // Convert Name To Image
  const convertNameToImage = (businessName: string) => {
    if (!businessName) {
      return 'N/A';
    }
    // const middleIndex = Math.floor(businessName.length / 2);
    // const firstPart = businessName.substring(0, middleIndex).trim();
    // const secondPart = businessName.substring(middleIndex).trim();
    // const firstInitial = firstPart.charAt(0).toUpperCase();
    // console.log('firstInitial: ', firstInitial);
    // const secondInitial = secondPart.charAt(0).toUpperCase();
    // console.log('secondInitial: ', secondInitial);
    const nameParts = businessName.trim().split(' ');
    const firstInitial = nameParts[0]?.charAt(0).toUpperCase() || '';
    const secondInitial = nameParts[1]?.charAt(0).toUpperCase() || '';

    return `${firstInitial}${secondInitial}`;
  };

  const [activeService22, setActiveService22] = useState<string | null>(null); // Allow null and string
  const [visibleRange, setVisibleRange] = useState([0, 5]);
  const handleNextTab = () => {
    const maxTabs = vendorData?.services?.data.length;
    if (maxTabs)
      if (visibleRange[1] < maxTabs) {
        setVisibleRange([visibleRange[0] + 1, visibleRange[1] + 1]);
      }
  };

  const handlePreviousTab = () => {
    if (visibleRange[0] > 0) {
      setVisibleRange([visibleRange[0] - 1, visibleRange[1] - 1]);
    }
  };

  const visibleTabs = vendorData?.services?.data.slice(
    visibleRange[0],
    visibleRange[1],
  );

  /* useEffect(() => {
     if(vendorData?.services?.data?.length){
     if (vendorData?.services?.data?.length > 0) {
       setActiveService22(vendorData.services.data[0]?.category_id);
     }
   }
   }, [vendorData]); */

  // Fetch Vendor Data

  useEffect(() => {
    const shopID = localStorage.getItem('shopID');
    if (shopID) {
      // Vendor Details
      const getVendorDetailsData = async () => {
        const vendorDetailsResult: vendorDetailsResponse =
          await getVendorDetailsFunc(parseInt(shopID));
        if (vendorDetailsResult === null) {
          toast.error('Failed to fetch vendor details.');
          return;
        }
        if (vendorDetailsResult?.status_code === 200) {
          let vendorData = vendorDetailsResult.data;
          let mediaImages: VendorMedia[] = [];
          let vendorMedia: VendorMedia[] =
            vendorData?.vendor_detail?.data?.vendorMedia ?? [];
          let businessLogoImage = '';
          let coverPhotoImage = '';
          if (vendorMedia) {
            let filterBusinessLogo = vendorMedia.filter((row: any) => {
              return row.is_logo === '1';
            });
            businessLogoImage =
              filterBusinessLogo && filterBusinessLogo?.length > 0
                ? filterBusinessLogo[0]?.media_name
                : '';
            let filterCoverPhoto = vendorMedia.filter((row: any) => {
              return row.is_cover === '1';
            });
            coverPhotoImage =
              filterCoverPhoto && filterCoverPhoto?.length > 0
                ? filterCoverPhoto[0]?.media_name
                : '';
            mediaImages = vendorMedia.filter((row: any) => {
              return row.is_cover !== 1 && row.is_logo !== 1;
            });
          }

          if (vendorData) {
            vendorData['business_logo'] = businessLogoImage;
            vendorData['cover_photo'] = coverPhotoImage;
            vendorData['media_images'] = mediaImages;
            const latValue =
              vendorData?.about_me?.data[0]?.lattitude ?? '23.027181141518188';
            const longValue =
              vendorData?.about_me?.data[0]?.longitude ?? '72.48185665364666';
            const latLongObject = {
              lat: parseFloat(latValue),
              lng: parseFloat(longValue),
            };
            setLatLong(latLongObject);
          }
          let activeService = vendorData?.services?.data[0].id ?? '0';
          setActiveService(activeService.toString());
          setVendorData(vendorData);

          if (vendorData && Array.isArray(vendorData?.services?.data)) {
            const vendorDataArr = vendorData.services.data;
            if (vendorDataArr.length > 0) {
              let firstServiceRecord = vendorData?.services?.data[0] || null;
              if (
                firstServiceRecord !== null &&
                (firstServiceRecord?.category_name === 'Combo Offer' ||
                  firstServiceRecord?.category_name === 'Packages') &&
                firstServiceRecord?.packages &&
                firstServiceRecord?.packages.length > 0
              ) {
                setIsServicesAvail(true);
              } else if (
                firstServiceRecord !== null &&
                firstServiceRecord?.services &&
                firstServiceRecord?.services.length > 0
              ) {
                setIsServicesAvail(true);
              }
            }
          }

          localStorage.setItem('vendor_data', JSON.stringify(vendorData));
          if (vendorData?.vendor_detail?.data?.is_favorite === '1') {
            setIsFav(true);
          }
        } else {
          toast.error(vendorDetailsResult?.message);
        }
      };

      getVendorDetailsData();
    }
  }, [getVendorDetailsFunc]);
  // Scroll

  const VISIBLE_ITEMS_COUNT = 4;
  const [startIndex, setStartIndex] = useState(0);
  const handleNext = () => {
    if (vendorData?.near_by?.data) {
      setStartIndex(prevIndex =>
        Math.min(
          prevIndex + VISIBLE_ITEMS_COUNT,
          vendorData.near_by.data.length - VISIBLE_ITEMS_COUNT,
        ),
      );
    }
  };
  const handlePrev = () => {
    setStartIndex(prevIndex => Math.max(prevIndex - VISIBLE_ITEMS_COUNT, 0));
  };

  const sliderlength = vendorData?.near_by.data.length || 0;
  const itemCountClass = `count${vendorData?.near_by.data.length || 0}`;

  const handleMoreClick = () => {
    // Add functionality for "More" tab click
  };

  const transformedImages = [
    ...(vendorData?.cover_photo
      ? [{ media_name: vendorData.cover_photo }]
      : vendorData?.media_images?.length
        ? [vendorData?.media_images[0]]
        : [{ media_name: images.glam9512 }]),
    ...(vendorData?.media_images?.filter(
      row => row.is_cover === '2' && row.is_logo === '2',
    ) || []),
  ];
  const transformedSlides = vendorData?.portfolio.data.map(
    (object: PortfolioItem) => ({
      src: object.media_name,
    }),
  );

  // Add/Update Favourite
  const addUpdateFavFunc = async () => {
    const shopID = localStorage.getItem('shopID');
    if (shopID && customerData) {
      const result: ApiResponse = await addRemoveFavVendorAPICall(
        parseInt(shopID),
      );
      if (result) {
        setIsFav(!isFav);
      } else {
        toast.error('Professional not found.');
      }
    } else {
      navigate('/login');
      //toast.error('Please login for add/update fav');
    }
  };
  const [customerData, setCustomerData] = useState<any>(null);
  const [isFav, setIsFav] = useState(false);
  useEffect(() => {
    let customerInfo = localStorage.getItem('customer_details');
    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
      setCustomerData(customerInfo);
    }
  }, []);

  const [lightisOpen, setLightIsOpen] = useState(false);
  const [currentLightIndex, setCurrentLightIndex] = useState(0);

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [socialdata, setSocialData] = useState<DataItem[]>([]);
  const vendor_detail_id = localStorage.getItem('shopID');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + '/get-vendor-detail';
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token =
          `Bearer ${localStorage.getItem('customer_auth_token')}` || '';
        const response = await fetch(apiBaseUrl, {
          method: 'POST', // Assuming POST is required
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({ vendor_detail_id }), // Pass shopID in the request body
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setSocialData(result.data.vendor_detail.data.social); // Assuming setData updates your state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [vendor_detail_id]); // Runs only once on mount

  const [openPackageDetails, setOpenPackageDetails] = useState(false);
  const [packageName, setPackageName] = useState('');
  const [pacakageCharges, setPacakageCharges] = useState('');
  const [pacakageTotalTime, setPacakageTotalTime] = useState('');
  const [pacakageServices, setPacakageServices] = useState<Service[]>([]);

  const handleOpenPackageDetails = (
    name: string,
    total_service_time: string,
    charges: string,
    services: Service[],
  ) => {
    setPacakageCharges(charges);
    setPacakageTotalTime(total_service_time);
    setPacakageServices(services);
    setPackageName(name);
    setOpenPackageDetails(true);
  };
  const handleClosePackageDetails = () => setOpenPackageDetails(false);

  const [scrollvalue, setScrollValue] = useState(0);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  useEffect(() => {
    // Check if there are more than 5 tabs
    {
      vendorData &&
        vendorData?.services?.data?.length > 5 &&
        setShowScrollButtons(vendorData?.services?.data?.length > 5);
    }
  }, [vendorData]);

  const handleCheckServicesAvailable = (obj: { [key: string]: any }) => {
    if (
      (obj?.category_name === 'Combo Offer' ||
        obj?.category_name === 'Packages') &&
      obj?.packages &&
      obj?.packages.length > 0
    ) {
      setIsServicesAvail(true);
    } else if (obj?.services && obj?.services.length > 0) {
      setIsServicesAvail(true);
    }
  };

  if (isLoading) {
    return (
      <div className="loaderCcontainer">
        <img src={images.loader} />
      </div>
    );
  } else {
    return (
      <>
        <Grid container spacing={2} className="shopMainDiv">
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="appt"
          >
            <AppointmentBooking handleClose={handleClose} />
          </BootstrapDialog>

          <Box
            className="homeShop onlyMobileSlider"
            sx={{
              position: 'relative',
              overflow: 'hidden',
              minHeight: '200px',
              width: '100%',
            }}
          >
            <Slider {...Settings}>
              {(vendorData?.media_images && vendorData.media_images.length > 0
                ? vendorData.media_images
                : Array(4).fill({ media_name: images.smallG9 })
              ) // Placeholder array if no media images
                .map((row: any, index: number) => (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    <Box
                      component="img"
                      sx={{
                        width: {
                          xs: '100%', // Full width on extra small screens
                          sm: '75%', // 75% width on small screens
                          md: '50%', // 50% width on medium screens
                        },
                        height: {
                          xs: '20rem', // Specific height for extra small screens
                          sm: '12.5rem', // Specific height for small screens
                          md: '17.813rem', // Specific height for medium screens
                        },
                        borderRadius: '5%',
                        cursor: 'pointer',
                        objectFit: 'cover',
                        marginBottom: '30px',
                      }}
                      alt={`Media Image ${index + 1}`}
                      src={row?.media_name ? row.media_name : images.glam9512}
                    />
                  </Grid>
                ))}
            </Slider>
          </Box>

          <Grid
            className="onlyDesktop"
            container
            spacing={2}
            sx={{ marginTop: '0px', marginBottom: '0px' }}
          >
            <Grid item xs={11} className="MainTitleGrid">
              <Box display="flex" alignItems="center" className="TitleImage">
                <Typography
                  variant="h2"
                  className="shopTitle"
                  sx={{ marginRight: 2 }}
                >
                  {vendorData?.business_logo !== '' ? (
                    <img
                      className="businessListImage"
                      src={vendorData?.business_logo}
                      alt="Crown"
                      style={{
                        marginRight: 5,
                      }} // Set size and margin
                    />
                  ) : (
                    <Box
                      className="crown"
                      sx={{
                        backgroundColor: '#231F20', // Background color for the image
                        borderRadius: '50%', // Makes the box round
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        float: 'left',
                        marginRight: '0.625rem',
                      }}
                    >
                      <Typography
                        variant="h4" // Adjust font size as needed
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '1.35rem',
                        }}
                      >
                        {convertNameToImage(
                          vendorData?.vendor_detail.data.business_name,
                        )}
                      </Typography>
                    </Box>
                  )}
                  {vendorData?.vendor_detail?.data?.salon_type == '1' && (
                    <img
                      className="crown"
                      src={images.crown}
                      alt="Crown"
                      style={{
                        marginRight: 10,
                      }} // Set size and margin
                    />
                  )}
                  <span
                    className={
                      vendorData?.business_logo !== ''
                        ? 'businessTitle withImage'
                        : 'businessTitle withInitials'
                    }
                  >
                    {vendorData?.vendor_detail.data.business_name}
                  </span>
                </Typography>
                <Rating
                  className={
                    vendorData?.vendor_detail?.data?.salon_type == '1'
                      ? 'titleBarRating titleWithCrown'
                      : 'titleBarRating titleWithoutCrown'
                  }
                  value={
                    vendorData?.vendor_detail?.data?.rating_value
                      ? parseInt(vendorData?.vendor_detail?.data?.rating_value)
                      : 0
                  }
                  precision={0.5}
                  readOnly
                  sx={{ marginRight: 2, fontSize: '2.188rem' }}
                />
                <Typography
                  variant="h2"
                  className={
                    vendorData?.vendor_detail?.data?.salon_type == '5'
                      ? 'shopTitleRating ratingCrown'
                      : 'shopTitleRating ratingWithoutCrown'
                  }
                >
                  {vendorData?.vendor_detail.data.rating}
                </Typography>
              </Box>
              <Grid item xs={3} xl={2} sm={3} className="Salon_Type">
                <Typography
                  className={
                    vendorData?.vendor_detail?.data?.salon_type !== '5'
                      ? 'withCrown'
                      : 'withoutCrown'
                  }
                >
                  {vendorData?.vendor_detail?.data?.salon_type === '1' ? (
                    <span style={{ marginLeft: '5px', fontWeight: '600' }}>
                      Luxury{' '}
                    </span>
                  ) : vendorData?.vendor_detail?.data?.salon_type === '2' ? (
                    <span style={{ marginLeft: '5px', fontWeight: '600' }}>
                      Premium{' '}
                    </span>
                  ) : vendorData?.vendor_detail?.data?.salon_type === '3' ? (
                    <span style={{ marginLeft: '5px', fontWeight: '600' }}>
                      Economy{' '}
                    </span>
                  ) : vendorData?.vendor_detail?.data?.salon_type === '4' ? (
                    <span style={{ marginLeft: '5px', fontWeight: '600' }}>
                      Standard{' '}
                    </span>
                  ) : vendorData?.vendor_detail?.data?.salon_type === '5' ? (
                    <span style={{ marginLeft: '5px', fontWeight: '600' }}>
                      Celebrity{' '}
                    </span>
                  ) : (
                    ''
                  )}
                  {vendorData?.vendor_detail?.data?.business_type === '1'
                    ? 'Salon'
                    : 'Professional'}
                </Typography>
              </Grid>

              <Box
                display="flex"
                className="shopOtherDetails"
                alignItems="center"
              >
                {vendorData?.vendor_detail.data.opening_status ? (
                  <Typography
                    variant="h4"
                    className={
                      vendorData?.vendor_detail.data.opening_status === 'Open'
                        ? 'shopOpened'
                        : 'shopClosed'
                    }
                    sx={{ marginRight: 2, marginTop: 4 }}
                  >
                    {vendorData?.vendor_detail.data.opening_status}
                  </Typography>
                ) : (
                  ''
                )}
                {vendorData?.vendor_detail.data.opening_time ? (
                  <>
                    <Typography
                      variant="h2"
                      className="shopOpensAt"
                      sx={{ marginRight: 2, marginTop: 4, marginLeft: 2 }}
                    >
                      - {vendorData?.vendor_detail.data.opening_time}
                    </Typography>
                  </>
                ) : (
                  ''
                )}
                {vendorData?.vendor_detail.data.address ? (
                  <>
                    <Typography
                      component="div"
                      className="dot"
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        backgroundColor: '#231F20',
                        borderRadius: '9999px',
                        marginRight: 2,
                        marginTop: 4,
                      }}
                    />
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        vendorData?.vendor_detail.data.address?.replace(
                          /,$/,
                          '',
                        ) || '',
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="google-maps-link"
                    >
                      <Typography
                        variant="h2"
                        className="shopOpensAt"
                        sx={{ marginRight: 2, marginTop: 4 }}
                      >
                        {vendorData?.vendor_detail.data.address?.replace(
                          /,$/,
                          '',
                        )}
                      </Typography>
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        vendorData?.vendor_detail.data.address?.replace(
                          /,$/,
                          '',
                        ) || '',
                      )}`}
                    >
                      <img
                        className="locationIcon"
                        src={images.location}
                        alt="Location"
                        style={{
                          width: 25,
                          height: 25,
                          marginRight: 5,
                          cursor: 'pointer',
                          marginBottom: 5,
                        }} // Set size and margin
                      />
                    </a>
                  </>
                ) : (
                  ''
                )}
              </Box>
            </Grid>
            <Grid item xs={1} className="like">
              <Checkbox
                onClick={() => {
                  addUpdateFavFunc();
                }}
                checked={isFav}
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
                sx={{
                  '&.Mui-checked': {
                    color: 'red',
                  },
                  height: 40,
                }}
              />
            </Grid>
            <Grid container alignItems="center" sx={{ marginTop: '15px' }}>
              {/* Call Now Button */}
              {/* Social Icons */}

              <Grid item xs={12} md={11} sm={1} xl={11}>
                <Grid
                  container
                  justifyContent="flex-start"
                  spacing={2}
                  sx={{ marginLeft: '15px' }}
                >
                  {socialdata &&
                    socialdata.length > 0 &&
                    socialdata.map((item: any, index: number) => (
                      <Grid
                        className="slIcon"
                        item
                        key={index}
                        sx={{ justifyContent: 'left' }}
                      >
                        <Link to={item.social_url} target="_blank">
                          <img
                            className="socialIcon"
                            src={item.icon}
                            alt={item.name}
                          />
                        </Link>
                      </Grid>
                    ))}
                  {vendorData?.vendor_detail?.data?.phone.value && (
                    <Grid
                      className="callNow"
                      spacing={1}
                      item
                      xs={12}
                      md={2}
                      sm={1}
                      xl={1}
                    >
                      <Link
                        to={`tel:${vendorData?.vendor_detail?.data?.phone.value}`}
                        target="_blank"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                          marginTop: '8px',
                          gap: '3px',
                          textDecoration: 'none',
                          color: 'black',
                          width: 'max-content',
                        }}
                      >
                        <CallIcon sx={{ color: 'black' }} />
                        {
                          vendorData?.vendor_detail?.data?.phone?.value &&
                            vendorData.vendor_detail.data.phone.value
                              ?.replace('tel: ', '') // Remove 'tel: '
                              .replace(/^91/, '+91 ') // Add the '+' before '91'
                              .replace(/(\d{5})(\d{5})$/, '$1 $2') // Format the remaining digits
                        }
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className="gallerySection onlyDesktop"
            container
            spacing={2}
            sx={{ marginTop: '0rem', marginBottom: '0.938rem' }}
          >
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                onClick={() => {
                  setCurrentLightIndex(0); // Set the current index
                  setIsOpen(true); // Open Lightbox
                }}
                className="coverImage"
                sx={{
                  width: '100%',
                  height: { xs: '18.75rem', sm: '36.75rem', md: '36.75rem' },
                  borderRadius: '5%',
                  cursor: 'pointer',
                  objectFit: 'cover',
                }}
                alt="Cover Image"
                src={
                  vendorData && vendorData.cover_photo
                    ? vendorData.cover_photo
                    : vendorData?.media_images &&
                        vendorData?.media_images?.length > 0
                      ? vendorData?.media_images[0].media_name
                      : images.glam9512
                }
              />
            </Grid>
            <div></div>
            <Grid item xs={12} md={6}>
              <Grid className="onlyDesktop" container spacing={2}>
                {(vendorData?.media_images && vendorData.media_images.length > 0
                  ? vendorData.media_images
                      .filter(
                        (row: any) =>
                          row.is_cover === '2' && row.is_logo === '2',
                      ) // Check conditions for is_cover and is_logo
                      .slice(0, 4) // Limit to 4 items
                  : Array(4).fill({
                      media_name: images.glam9512,
                    })
                ) // Placeholder array if no media images
                  .map((row: any, index: number) => (
                    <Grid item xs={6} sm={6} md={6} key={index}>
                      <Box
                        component="img"
                        onClick={() => {
                          setCurrentLightIndex(index + 1); // Set the current index
                          setIsOpen(true); // Open Lightbox
                        }}
                        sx={{
                          width: '100%',
                          height: {
                            xs: '9.375rem',
                            sm: '12.5rem',
                            md: '17.813rem',
                          }, // Responsive heights
                          borderRadius: '5%',
                          cursor: 'pointer',
                          objectFit: 'cover',
                        }}
                        alt={`Media Image ${index + 1}`}
                        src={row?.media_name ? row.media_name : images.glam9512}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
          <Lightbox
            open={isOpen}
            close={() => setIsOpen(false)}
            slides={transformedImages.map(image => ({
              src: image.media_name,
            }))}
            index={currentLightIndex}
          />
          {/* <ShopMobile shopMobileProp={vendorData} /> */}

          <Grid container className="onlyMobile">
            <h2>
              {vendorData?.vendor_detail.data.opening_status ? (
                <Typography
                  variant="h4"
                  className={
                    vendorData?.vendor_detail.data.opening_status === 'Open'
                      ? 'shopOpened'
                      : 'shopClosed'
                  }
                  sx={{ marginRight: 2, marginTop: 4 }}
                >
                  {vendorData?.vendor_detail.data.opening_status}
                </Typography>
              ) : (
                ''
              )}
              {vendorData?.vendor_detail.data.opening_time ? (
                <>
                  <Typography
                    variant="h3"
                    className="shopOpensAt"
                    sx={{ marginRight: 2, marginTop: 4, marginLeft: 2 }}
                  >
                    - {vendorData?.vendor_detail.data.opening_time}
                  </Typography>
                </>
              ) : (
                ''
              )}
            </h2>
          </Grid>

          <Grid container className="onlyMobile">
            <Grid item xs={11}>
              {vendorData?.vendor_detail.data.address ? (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    vendorData?.vendor_detail.data.address?.replace(/,$/, '') ||
                      '',
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="google-maps-link"
                >
                  <Typography
                    variant="h4"
                    className="shopOpensAt"
                    sx={{ marginRight: 2, marginTop: 4 }}
                  >
                    {vendorData?.vendor_detail.data.address?.replace(/,$/, '')}
                  </Typography>
                </a>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={1}>
              <img
                className="locationIcon"
                src={images.location}
                alt="Location"
                style={{
                  width: 25,
                  height: 25,
                  marginRight: 5,
                  marginBottom: 5,
                }} // Set size and margin
              />
            </Grid>
          </Grid>

          <Grid container className="onlyMobile" sx={{ marginTop: '15px' }}>
            {/* Call Now Button */}
            {/* Social Icons */}

            <Grid item xs={12} md={11} sm={1} xl={11}>
              <Grid
                container
                justifyContent="flex-start"
                spacing={2}
                sx={{ marginLeft: '0px', marginTop: '0px' }}
              >
                {socialdata &&
                  socialdata.length > 0 &&
                  socialdata.map((item: any, index: number) => (
                    <Grid
                      className="slIcon"
                      item
                      key={index}
                      sx={{ justifyContent: 'left' }}
                    >
                      <Link to={item.social_url} target="_blank">
                        <img
                          className="socialIcon"
                          src={item.icon}
                          alt={item.name}
                        />
                      </Link>
                    </Grid>
                  ))}
                {vendorData?.vendor_detail?.data?.phone.value && (
                  <Grid
                    className="callNow"
                    spacing={1}
                    item
                    xs={12}
                    md={2}
                    sm={1}
                    xl={1}
                  >
                    <Link
                      to={`tel:${vendorData?.vendor_detail?.data?.phone.value}`}
                      target="_blank"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        marginTop: '0px',
                        gap: '3px',
                        textDecoration: 'none',
                        color: 'black',
                        width: 'max-content',
                      }}
                    >
                      <CallIcon sx={{ color: 'black' }} />
                      {
                        vendorData?.vendor_detail?.data?.phone?.value &&
                          vendorData.vendor_detail.data.phone.value
                            ?.replace('tel: ', '') // Remove 'tel: '
                            .replace(/^91/, '+91 ') // Add the '+' before '91'
                            .replace(/(\d{5})(\d{5})$/, '$1 $2') // Format the remaining digits
                      }
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <hr style={{ width: '100%', marginTop: '10px' }}></hr>
          <Typography
            variant="h2"
            className="services onlyMobileSlider"
            sx={{ marginRight: 2 }}
          >
            Services
          </Typography>
          {vendorData && vendorData?.services?.data?.length > 0 ? (
            <Grid
              container
              item
              xs={12}
              sx={{ paddingLeft: '0px' }}
              className="serviceSection"
            >
              {/* Services Section */}
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid item xs={12}>
                  <Grid container item xs={12}>
                    <Grid item xs={0} xl={2}>
                      <Typography
                        variant="h2"
                        className="services onlyDesktop"
                        sx={{ marginRight: 2 }}
                      >
                        Services
                      </Typography>
                    </Grid>
                    <Grid item xs={12} xl={10}>
                      <Box
                        sx={{
                          maxWidth: { xs: 320, sm: 480 },
                          bgcolor: 'background.paper',
                        }}
                      ></Box>

                      <Box
                        className="tabs vendorService"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'hidden', // Ensure content doesn't overflow the container
                        }}
                      >
                        {/* Tabs Container */}
                        <Box
                          className="tabContainer"
                          sx={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            flex: 1,
                            '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Webkit browsers
                            scrollbarWidth: 'none', // Hide scrollbar for Firefox
                          }}
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={showScrollButtons}
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                          >
                            {vendorData?.services?.data?.map((data, index) => (
                              <Tab
                                label={data.category_name}
                                value={data.id ? data.id.toString() : '0'}
                                key={`vendor_service_${data.id}_${data.category_id}`}
                                onClick={() => {
                                  setActiveService(data.id);
                                  setIsServicesAvail(false);
                                  handleCheckServicesAvailable(data);
                                }}
                                className={
                                  activeService === data.id
                                    ? data.category_id == ''
                                      ? 'activeServiceBlack' // Active tab with black styling
                                      : 'activeServicePurple' // Active tab with purple styling
                                    : 'servicesTabs' // Default inactive styling
                                }
                              />
                            ))}
                          </Tabs>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* Service List Section */}
                  <Grid
                    className="onlyMobileSlider"
                    item
                    xs={12}
                    sx={{ marginTop: 3 }}
                  >
                    {vendorData?.services?.data.map(data => (
                      <div
                        role="tabpanel"
                        hidden={activeService !== data?.id}
                        id={`tabpanel-${data?.id}`}
                        key={`tabpanel-${data?.id}-${data?.id}`}
                      >
                        {activeService === data?.id &&
                          (data?.services?.length > 0 ? (
                            <Grid className="abd" container spacing={2}>
                              {data.services.length > 5 &&
                                data?.services
                                  .slice(0, 5)
                                  .map((item, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      key={`tabpanel-${item.id}-${data?.id}-${data?.category_id}`}
                                    >
                                      <Box
                                        key={`box-${item.id}-${data?.id}-${data?.category_id}`}
                                        sx={{
                                          width: '100%',
                                          height: '100%',
                                          position: 'relative',
                                          display: 'inline-block',
                                        }}
                                        className="serviceContent"
                                      >
                                        {/* Main Card Background */}
                                        <Box
                                          className="mainCardBackground"
                                          sx={{
                                            width: '100%',
                                            height: '4.375rem',
                                            position: 'relative',
                                            backgroundColor: 'white',
                                            borderRadius: '0.625rem',
                                            border: '1px solid #231F20',
                                            top: 0,
                                            left: 0,
                                          }}
                                        />

                                        {/* Book Button */}
                                        {vendorData?.vendor_detail?.data
                                          ?.is_online_book === '1' &&
                                          Number(
                                            vendorData?.vendor_detail?.data
                                              ?.subscription_plan_id,
                                          ) > 1 && (
                                            <Box
                                              className="bookButton"
                                              sx={{
                                                width: '6.063rem',
                                                height: '2.5rem',
                                                position: 'absolute',
                                                right: {
                                                  xs: '0.625rem',
                                                  sm: '0.938rem',
                                                },
                                                top: '0.938rem',
                                              }}
                                            >
                                              <Button
                                                sx={{
                                                  width: '100%',
                                                  height: '100%',
                                                  fontSize: '0.875rem',
                                                  backgroundColor: '#4E297B',
                                                  borderRadius: '0.625rem',
                                                  position: 'absolute',
                                                  padding: 0,
                                                  '&:hover': {
                                                    backgroundColor: '#1a1a1a',
                                                  },
                                                }}
                                                onClick={() =>
                                                  handleClickOpen(
                                                    item?.category_id,
                                                    item,
                                                  )
                                                }
                                              >
                                                <Typography
                                                  sx={{
                                                    color: 'white',
                                                    fontSize: '0.875rem',
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 400,
                                                    lineHeight: '1.65rem',
                                                    textTransform: 'capitalize',
                                                    padding: '5px',
                                                  }}
                                                >
                                                  Book Now
                                                </Typography>
                                              </Button>
                                            </Box>
                                          )}

                                        {/* Price */}
                                        {/* {vendorData?.vendor_detail?.data
                                    ?.is_online_book === '1' &&
                                    Number(
                                      vendorData?.vendor_detail?.data
                                        ?.subscription_plan_id,
                                    ) > 1 && ( */}
                                        <Typography
                                          className={
                                            vendorData?.vendor_detail?.data
                                              ?.is_online_book === '1' &&
                                            Number(
                                              vendorData?.vendor_detail?.data
                                                ?.subscription_plan_id,
                                            ) > 1
                                              ? 'servicePrice '
                                              : 'servicePrice pushToRight'
                                          }
                                          sx={{
                                            position: 'absolute',
                                            right: { xs: '6rem', sm: '7.5rem' },
                                            top: '18px',
                                            color: '#231F20',
                                            fontSize: {
                                              xs: '0.488rem',
                                              sm: '1.4rem',
                                              md: '0.625rem',
                                              lg: '0.900rem',
                                              xl: '1rem',
                                            },
                                            fontFamily: 'Poppins',
                                            fontWeight: 700,
                                            lineHeight: '2.1rem',
                                          }}
                                        >
                                          {item.currency.currency_icon}
                                          {item.charges}
                                        </Typography>
                                        {/* )} */}

                                        {/* Service Name and Process Time */}
                                        <Box
                                          className="serviceName"
                                          sx={{
                                            position: 'absolute',
                                            left: '0.938rem',
                                            top: '0.625rem',
                                          }}
                                        >
                                          <Typography
                                            className="serviceTitle"
                                            sx={{
                                              color: '#231F20',
                                              fontSize: {
                                                xs: '0.55rem',
                                                sm: '0.463rem',
                                                md: '0.7333rem',
                                                lg: '0.8000rem',
                                                xl: '1.1458rem',
                                              },
                                              fontFamily: 'Poppins',
                                              fontWeight: 500,
                                              lineHeight: {
                                                xs: '26.4px',
                                                sm: '20.4px',
                                              },
                                              marginTop: '0.5rem',
                                            }}
                                          >
                                            {item.service_name}
                                          </Typography>
                                          <Typography
                                            className="serviceDuration"
                                            sx={{
                                              color: '#231F20',
                                              fontSize: {
                                                xs: '12px',
                                                sm: '9px',
                                                md: '0.5666rem',
                                                lg: '0.7500rem',
                                                xl: '0.8333rem',
                                              },
                                              fontFamily: 'Poppins',
                                              fontWeight: 300,
                                              lineHeight: '19.2px',
                                              width: {
                                                xs: '80%',
                                                sm: '200px',
                                                md: '100px',
                                                lg: '120px',
                                                xl: '250px',
                                              },
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              opacity: 0.6,
                                            }}
                                          >
                                            Process Time: {item.service_time}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  ))}
                            </Grid>
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  color: 'black',
                                  textAlign: 'center',
                                  mt: 2,
                                  fontSize: '22px',
                                }}
                              >
                                {data?.category_name === 'Combo Offer' ||
                                data?.category_name === 'Packages' ? (
                                  data?.packages &&
                                  data?.packages.length > 0 ? (
                                    data?.packages.map(
                                      (item: any, index: number) => (
                                        <div
                                          key={`tabpanel-${item.id}-${data?.id}-${data?.category_id}`}
                                        >
                                          <Box
                                            key={`box-${item.id}-${data?.id}-${data?.category_id}`}
                                            sx={{
                                              width: {
                                                xs: '100%',
                                                sm: '48%',
                                                md: '48%',
                                              },
                                              height: '100%',
                                              position: 'relative',
                                              mb: 2,
                                              float: 'left',
                                              marginRight: {
                                                xs: 0,
                                                sm: '0.625rem',
                                              },
                                              display: 'inline-block',
                                            }}
                                            className="serviceContent"
                                          >
                                            {/* Main Card Background */}
                                            <Box
                                              className="mainCardBackground"
                                              sx={{
                                                width: '100%',
                                                height: '4.375rem',
                                                position: 'relative',
                                                backgroundColor: 'white',
                                                borderRadius: '0.625rem',
                                                border: '1px solid #231F20',
                                                top: 0,
                                                left: 0,
                                              }}
                                            />
                                            {/* Book Button */}
                                            {vendorData?.vendor_detail?.data
                                              ?.is_online_book === '1' &&
                                              Number(
                                                vendorData?.vendor_detail?.data
                                                  ?.subscription_plan_id,
                                              ) > 1 && (
                                                <Box
                                                  className="bookButton"
                                                  sx={{
                                                    width: '6.063rem',
                                                    height: '2.5rem',
                                                    position: 'absolute',
                                                    right: {
                                                      xs: '0.625rem',
                                                      sm: '0.938rem',
                                                    },
                                                    top: '0.938rem',
                                                  }}
                                                >
                                                  <Button
                                                    sx={{
                                                      width: '100%',
                                                      height: '100%',
                                                      fontSize: '0.875rem',
                                                      backgroundColor:
                                                        '#4E297B',
                                                      borderRadius: '0.625rem',
                                                      position: 'absolute',
                                                      padding: 0,
                                                      '&:hover': {
                                                        backgroundColor:
                                                          '#1a1a1a',
                                                      },
                                                      marginLeft: '-40px',
                                                    }}
                                                    onClick={() => {
                                                      handleClickOpen(
                                                        item?.category_id,
                                                        item,
                                                      );
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        color: 'white',
                                                        fontSize: '0.875rem',
                                                        fontFamily: 'Poppins',
                                                        fontWeight: 400,
                                                        lineHeight: '1.65rem',
                                                        textTransform:
                                                          'capitalize',
                                                        padding: '5px',
                                                      }}
                                                    >
                                                      Book Now
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            {/* Price */}
                                            <Typography
                                              className={
                                                vendorData?.vendor_detail?.data
                                                  ?.is_online_book === '1' &&
                                                Number(
                                                  vendorData?.vendor_detail
                                                    ?.data
                                                    ?.subscription_plan_id,
                                                ) > 1
                                                  ? 'servicePrice '
                                                  : 'servicePrice pushToRight'
                                              }
                                              sx={{
                                                position: 'absolute',
                                                right: {
                                                  xs: '7.5rem',
                                                  sm: '7.5rem',
                                                },
                                                top: '18px',
                                                color: '#231F20',
                                                fontSize: {
                                                  xs: '0.688rem',
                                                  sm: '1.4rem',
                                                  md: '0.625rem',
                                                  lg: '0.900rem',
                                                  xl: '1.4rem',
                                                },
                                                fontFamily: 'Poppins',
                                                fontWeight: 700,
                                                lineHeight: '2.1rem',
                                              }}
                                            >
                                              {item.amount_type === '1' ||
                                              item.amount_type === '2' ? (
                                                <>
                                                  {item.currency.currency_icon}
                                                  {item.charges}
                                                </>
                                              ) : (
                                                'Free'
                                              )}
                                            </Typography>
                                            {/* Service Name and Process Time */}
                                            <Box
                                              className="serviceName"
                                              sx={{
                                                position: 'absolute',
                                                left: '0.938rem',
                                                top: '0.625rem',
                                                width: 'inherit',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                handleOpenPackageDetails(
                                                  item.package_name,
                                                  item.total_service_time,
                                                  item.charges,
                                                  item.services,
                                                )
                                              }
                                            >
                                              <Typography
                                                sx={{
                                                  color: '#231F20',
                                                  fontSize: {
                                                    xs: '0.75rem',
                                                    sm: '0.563rem',
                                                    md: '0.7333rem',
                                                    lg: '0.8000rem',
                                                    xl: '1.1458rem',
                                                  },
                                                  fontFamily: 'Poppins',
                                                  fontWeight: 500,
                                                  lineHeight: {
                                                    xs: '26.4px',
                                                    sm: '20.4px',
                                                  },
                                                  width: 'fit-content',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  marginTop: '0.5rem',
                                                  textOverflow: 'ellipsis',
                                                }}
                                              >
                                                {item.package_name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  color: '#231F20',
                                                  fontSize: {
                                                    xs: '12px',
                                                    sm: '9px',
                                                    md: '0.5666rem',
                                                    lg: '0.7500rem',
                                                    xl: '0.8333rem',
                                                  },
                                                  fontFamily: 'Poppins',
                                                  fontWeight: 300,
                                                  lineHeight: '19.2px',
                                                  width: 'fit-content',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  opacity: 0.6,
                                                }}
                                              >
                                                Process Time:{' '}
                                                {item.total_service_time}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </div>
                                      ),
                                    )
                                  ) : (
                                    <Typography>No packages found</Typography>
                                  )
                                ) : (
                                  'Sevices Not Found'
                                )}
                              </Typography>
                            </>
                          ))}
                      </div>
                    ))}
                    <Button
                      onClick={viewMoreServices}
                      className="btnMoreServices"
                    >
                      More Services
                    </Button>
                  </Grid>

                  <Grid
                    className="onlyDesktop"
                    item
                    xs={12}
                    sx={{ marginTop: 3 }}
                  >
                    {vendorData?.services?.data.map(data => (
                      <div
                        role="tabpanel"
                        hidden={activeService !== data?.id}
                        id={`tabpanel-${data?.id}`}
                        key={`tabpanel-${data?.id}-${data?.id}`}
                      >
                        {activeService === data?.id &&
                          (data?.services?.length > 0 ? (
                            <Grid className="abd" container spacing={2}>
                              {data?.services.map((item, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  key={`tabpanel-${item.id}-${data?.id}-${data?.category_id}`}
                                >
                                  <Box
                                    key={`box-${item.id}-${data?.id}-${data?.category_id}`}
                                    sx={{
                                      width: '100%',
                                      height: '100%',
                                      position: 'relative',
                                      display: 'inline-block',
                                    }}
                                    className="serviceContent"
                                  >
                                    {/* Main Card Background */}
                                    <Box
                                      className="mainCardBackground"
                                      sx={{
                                        width: '100%',
                                        height: '4.375rem',
                                        position: 'relative',
                                        backgroundColor: 'white',
                                        borderRadius: '0.625rem',
                                        border: '1px solid #231F20',
                                        top: 0,
                                        left: 0,
                                      }}
                                    >
                                      {/* Book Button */}
                                      {vendorData?.vendor_detail?.data
                                        ?.is_online_book === '1' &&
                                        Number(
                                          vendorData?.vendor_detail?.data
                                            ?.subscription_plan_id,
                                        ) > 1 && (
                                          <Box className="bookButton">
                                            <Button
                                              onClick={() =>
                                                handleClickOpen(
                                                  item?.category_id,
                                                  item,
                                                )
                                              }
                                            >
                                              <Typography>Book Now</Typography>
                                            </Button>
                                          </Box>
                                        )}
                                      <Typography
                                        className={
                                          vendorData?.vendor_detail?.data
                                            ?.is_online_book === '1' &&
                                          Number(
                                            vendorData?.vendor_detail?.data
                                              ?.subscription_plan_id,
                                          ) > 1
                                            ? 'servicePrice '
                                            : 'servicePrice pushToRight'
                                        }
                                      >
                                        {item.currency.currency_icon}
                                        {item.charges}
                                      </Typography>
                                      {/* )} */}

                                      {/* Service Name and Process Time */}
                                      <Box
                                        className="serviceName"
                                        sx={{
                                          position: 'absolute',
                                          left: '0.938rem',
                                          top: '0.625rem',
                                        }}
                                      >
                                        <Typography className="serviceTitle">
                                          {item.service_name}
                                        </Typography>
                                        <Typography className="serviceDuration">
                                          Process Time: {item.service_time}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  color: 'black',
                                  textAlign: 'center',
                                  mt: 2,
                                  fontSize: '22px',
                                }}
                              >
                                {data?.category_name === 'Combo Offer' ||
                                data?.category_name === 'Packages' ? (
                                  data?.packages &&
                                  data?.packages.length > 0 ? (
                                    data?.packages.map(
                                      (item: any, index: number) => (
                                        <div
                                          key={`tabpanel-${item.id}-${data?.id}-${data?.category_id}`}
                                        >
                                          <Box
                                            key={`box-${item.id}-${data?.id}-${data?.category_id}`}
                                            sx={{
                                              width: {
                                                xs: '100%',
                                                sm: '48%',
                                                md: '48%',
                                              },
                                              height: '100%',
                                              position: 'relative',
                                              mb: 2,
                                              float: 'left',
                                              marginRight: {
                                                xs: 0,
                                                sm: '0.625rem',
                                              },
                                              display: 'inline-block',
                                            }}
                                            className="serviceContent"
                                          >
                                            {/* Main Card Background */}
                                            <Box
                                              className="mainCardBackground"
                                              sx={{
                                                width: '100%',
                                                height: '4.375rem',
                                                position: 'relative',
                                                backgroundColor: 'white',
                                                borderRadius: '0.625rem',
                                                border: '1px solid #231F20',
                                                top: 0,
                                                left: 0,
                                              }}
                                            />
                                            {/* Book Button */}
                                            {vendorData?.vendor_detail?.data
                                              ?.is_online_book === '1' &&
                                              Number(
                                                vendorData?.vendor_detail?.data
                                                  ?.subscription_plan_id,
                                              ) > 1 && (
                                                <Box
                                                  className="bookButton"
                                                  sx={{
                                                    width: '6.063rem',
                                                    height: '2.5rem',
                                                    position: 'absolute',
                                                    right: {
                                                      xs: '0.625rem',
                                                      sm: '0.938rem',
                                                    },
                                                    top: '0.938rem',
                                                  }}
                                                >
                                                  <Button
                                                    sx={{
                                                      width: '100%',
                                                      height: '100%',
                                                      fontSize: '0.875rem',
                                                      backgroundColor:
                                                        '#4E297B',
                                                      borderRadius: '0.625rem',
                                                      position: 'absolute',
                                                      padding: 0,
                                                      '&:hover': {
                                                        backgroundColor:
                                                          '#1a1a1a',
                                                      },
                                                      marginLeft: '-40px',
                                                    }}
                                                    onClick={() => {
                                                      handleClickOpen(
                                                        item?.category_id,
                                                        item,
                                                      );
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        color: 'white',
                                                        fontSize: '0.875rem',
                                                        fontFamily: 'Poppins',
                                                        fontWeight: 400,
                                                        lineHeight: '1.65rem',
                                                        textTransform:
                                                          'capitalize',
                                                        padding: '5px',
                                                      }}
                                                    >
                                                      Book Now
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            {/* Price */}
                                            <Typography
                                              className={
                                                vendorData?.vendor_detail?.data
                                                  ?.is_online_book === '1' &&
                                                Number(
                                                  vendorData?.vendor_detail
                                                    ?.data
                                                    ?.subscription_plan_id,
                                                ) > 1
                                                  ? 'servicePrice '
                                                  : 'servicePrice pushToRight'
                                              }
                                              sx={{
                                                position: 'absolute',
                                                right: {
                                                  xs: '7.5rem',
                                                  sm: '7.5rem',
                                                },
                                                top: '18px',
                                                color: '#231F20',
                                                fontSize: {
                                                  xs: '0.688rem',
                                                  sm: '1.4rem',
                                                  md: '0.625rem',
                                                  lg: '0.900rem',
                                                  xl: '1.5rem',
                                                },
                                                fontFamily: 'Poppins',
                                                fontWeight: 700,
                                                lineHeight: '2.1rem',
                                              }}
                                            >
                                              {item.amount_type === '1' ||
                                              item.amount_type === '2' ? (
                                                <>
                                                  {item.currency.currency_icon}
                                                  {item.charges}
                                                </>
                                              ) : (
                                                'Free'
                                              )}
                                            </Typography>
                                            {/* Service Name and Process Time */}
                                            <Box
                                              className="serviceName"
                                              sx={{
                                                position: 'absolute',
                                                left: '0.938rem',
                                                top: '0.625rem',
                                                width: 'inherit',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                handleOpenPackageDetails(
                                                  item.package_name,
                                                  item.total_service_time,
                                                  item.charges,
                                                  item.services,
                                                )
                                              }
                                            >
                                              <Typography
                                                sx={{
                                                  color: '#231F20',
                                                  fontSize: {
                                                    xs: '0.75rem',
                                                    sm: '0.563rem',
                                                    md: '0.7333rem',
                                                    lg: '0.8000rem',
                                                    xl: '1.1458rem',
                                                  },
                                                  fontFamily: 'Poppins',
                                                  fontWeight: 500,
                                                  lineHeight: {
                                                    xs: '26.4px',
                                                    sm: '20.4px',
                                                  },
                                                  width: 'fit-content',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  marginTop: '0.5rem',
                                                  textOverflow: 'ellipsis',
                                                }}
                                              >
                                                {item.package_name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  color: '#231F20',
                                                  fontSize: {
                                                    xs: '12px',
                                                    sm: '9px',
                                                    md: '0.5666rem',
                                                    lg: '0.7500rem',
                                                    xl: '0.8333rem',
                                                  },
                                                  fontFamily: 'Poppins',
                                                  fontWeight: 300,
                                                  lineHeight: '19.2px',
                                                  width: 'fit-content',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  opacity: 0.6,
                                                }}
                                              >
                                                Process Time:{' '}
                                                {item.total_service_time}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </div>
                                      ),
                                    )
                                  ) : (
                                    <Typography>No packages found</Typography>
                                  )
                                ) : (
                                  'Sevices Not Found'
                                )}
                              </Typography>
                            </>
                          ))}
                      </div>
                    ))}
                  </Grid>
                </Grid>
                {vendorData?.vendor_detail?.data?.is_online_book === '1' && (
                  <TeamMember TeamMemberProps={vendorData?.our_team} />
                )}
                <About
                  title={vendorData?.vendor_detail.data.business_name ?? ''}
                  description={vendorData?.vendor_detail.data.description ?? ''}
                  address={vendorData?.vendor_detail.data.address ?? ''}
                  nearbyAreas=""
                  latLong={latLong}
                />
                <div className="onlyMobileSlider">
                  <div className="divider " />
                  <OpeningTimes OpeningTimesProp={vendorData} />
                </div>
                {vendorData?.academic?.data?.length > 0 && (
                  <>
                    <AcademyVideos data={vendorData?.academic.data} />
                    <Box
                      sx={{
                        mt: 7,
                        maxWidth: '100%',
                        height: '1px',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        width: 'auto',
                      }}
                    />
                  </>
                )}
                {vendorData?.portfolio?.data?.length > 0 && (
                  <section className="photos">
                    <Typography
                      variant="h4"
                      sx={{
                        mt: 4,
                        fontWeight: '600',
                        color: 'text.primary',
                        fontSize: '1.875rem',
                      }}
                    >
                      Photos
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        maxWidth: '100%',
                        width: 'auto',
                        '@media (max-width: 960px)': { mt: 10 },
                      }}
                    >
                      <Grid container spacing={2}>
                        {vendorData.portfolio.data.map(
                          (object: PortfolioItem, index: number) => (
                            <Grid
                              className="photoContainer"
                              item
                              xs={12}
                              sm={6}
                              md={3}
                              key={index}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                              >
                                <img
                                  loading="lazy"
                                  onClick={() => {
                                    setCurrentPhotoIndex(index); // Set the current index
                                    setOpenPhoto(true); // Open Lightbox
                                  }}
                                  src={object.media_name}
                                  alt={`salon_${index - 30}`}
                                  style={{
                                    objectFit: 'cover',
                                    maxWidth: '100%',
                                    aspectRatio: '1',
                                    width: 'auto',
                                    marginTop: '5px',
                                    borderRadius: '20px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Box>
                            </Grid>
                          ),
                        )}
                      </Grid>
                      <Lightbox
                        open={openPhoto}
                        close={() => setOpenPhoto(false)}
                        slides={transformedSlides}
                        index={currentPhotoIndex}
                      />
                    </Box>
                  </section>
                )}
                {vendorData.review?.data?.length > 0 && (
                  <Reviews
                    salonName={vendorData?.vendor_detail.data.business_name}
                    reviews={vendorData.review.data}
                    overallRating={vendorData.vendor_detail.data.rating_value}
                    totalReviews={
                      vendorData.vendor_detail.data.total_review ?? 0
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={4} sm={12} md={4} className="onlyDesktop">
                <Sidebar
                  vendorData={vendorData}
                  convertNameToImage={convertNameToImage}
                  isServicesAvail={isServicesAvail}
                  servicesDataOfVendor={servicesDataOfVendor}
                ></Sidebar>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {vendorData?.near_by.data?.length && (
            <section className="nearBy onlyDesktop">
              <Box
                sx={{
                  position: 'relative',
                  maxWidth: 'auto',
                  margin: '0 auto',
                }}
              >
                <Typography
                  variant="h2"
                  className="mt-8"
                  sx={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    color: 'text.primary',
                    marginTop: '35px',
                  }}
                >
                  Nearby Salons
                </Typography>
                {sliderlength > 4 && (
                  <Button
                    onClick={handlePrev}
                    sx={{
                      position: 'absolute',
                      left: '-20px', // Adjust as needed to position outside the grid
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                    disabled={startIndex === 0}
                  >
                    <img src={icons.left} alt="Left" />
                  </Button>
                )}

                <Grid
                  container
                  className={`nearByContainer ${itemCountClass}`}
                  spacing={2}
                  sx={{ width: '100%', mt: 0 }}
                >
                  {vendorData?.near_by.data
                    .slice(startIndex, startIndex + VISIBLE_ITEMS_COUNT)
                    .map((salon, index) => (
                      <Grid item key={index} xs={12} sm={6} md={3} lg={3}>
                        <NearbySalon
                          key={index}
                          data={salon}
                          onShopClick={handleNearbyShopClick}
                        />
                      </Grid>
                    ))}
                </Grid>
                {sliderlength > 4 && (
                  <Button
                    onClick={handleNext}
                    sx={{
                      position: 'absolute',
                      right: '-40px', // Adjust as needed to position outside the grid
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <img src={icons.right} alt="Right" />
                  </Button>
                )}
              </Box>
            </section>
          )}
        </Grid>
        {openPackageDetails && (
          <ViewPackageDetailsPopups
            open={openPackageDetails}
            handleClose={handleClosePackageDetails}
            name={packageName}
            price={pacakageCharges}
            time={pacakageTotalTime}
            services={pacakageServices}
          />
        )}
      </>
    );
  }
};
export default Shop;
