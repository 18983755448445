import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, Button } from '@mui/material';
import { images } from '../../Assets/images';
import Offersection from '../home/Offersection/Offersection';
import { useLocation } from 'react-router-dom';
import useAppContext from '../../hooks/useAppContext';
import { offersDataResponse } from '../../api/types/dashboard';
import toast from 'react-hot-toast';

interface propState {
  offerType: string;
}

const VendorOffers: React.FC = () => {
  const location = useLocation();
  let offerType = location?.state?.offerType || '';

  let pathName = location?.pathname || '';

  const [vendorOffersData, setVendorOffersData] = useState<any>({});
  const [vendorOffersLoading, setVendorOffersLoading] =
    useState<boolean>(false);

  const { getVendorOffersFunc } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]); //

  const fetchVendorOffersList = useCallback(async () => {
    try {
      setVendorOffersLoading(true);

      const vendorOffersData: offersDataResponse = await getVendorOffersFunc();

      if (!vendorOffersData) {
        toast.error('Failed to fetch vendor offers.');

        return;
      }
      if (vendorOffersData?.status_code === 200) {
        setVendorOffersData(vendorOffersData.data);
      } else {
        toast.error(vendorOffersData?.message || 'Error fetching data.');
      }
    } catch (error: any) {
      toast.error(
        error.message || 'An error occurred while fetching vendor offers.',
      );
    } finally {
      setVendorOffersLoading(false); // Ensures loading state is handled properly
    }
  }, [getVendorOffersFunc]);

  useEffect(() => {
    // Vendor Offers List
    fetchVendorOffersList();
  }, [fetchVendorOffersList]);

  return (
    <>
      <Grid
        container
        className="offerContainer"
        spacing={2}
        sx={{ marginTop: { xl: '50px', xs: '20px' }, marginBottom: '100px' }}
      >
        {Array.isArray(vendorOffersData?.[offerType]) &&
          (vendorOffersData?.[offerType] || []).map(
            (offer: any, index: number) => (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index + 1}>
                <Offersection data={offer} />
              </Grid>
            ),
          )}
      </Grid>
    </>
  );
};

export default VendorOffers;
