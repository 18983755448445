import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './PaymentMethodsSection.css';

const PaymentMethodsSection = () => {
  return (
    <Box className="payment-methods-section">
      <Typography variant="h5">My Payment Methods</Typography>
      <Typography className="description" variant="body1">
        Securely save your card details for hassle-free payments.
      </Typography>
      <Button className="add-payment-button">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/eb22e58d5fb304b5a7d753432b0c9065f24cefa343385c28261b77ca4fde987b?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
          className="payment-method-image"
          alt="Add Payment Method"
        />
        <span className="button-text">Add Payment Method</span>
      </Button>
    </Box>
  );
};

export default PaymentMethodsSection;
