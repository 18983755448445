import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Divider } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import { icons } from '../../../../src/Assets/icons';
import { images } from '../../../../src/Assets/images';
import './Footer.css';

const Footer = (): JSX.Element => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setShow(false);
    let currentPage = location.pathname.toString();
    let notAllowedPages: string[] = [
      '/login',
      '/register',
      '/otp',
      '/search',
      '/reviews',
      '/appointment',
    ];
    if (!notAllowedPages.some(page => currentPage.includes(page))) {
      setShow(true);
    }
  }, [location]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      {show && (
        <Box
          className="footerContainer"
          sx={{
            width: '100%',
            backgroundColor: '#231F20',
            color: 'white',
            top: 0,
          }}
        >
          <Container className="footer" sx={{ maxwidth: '100%' }}>
            <Grid container spacing={4} sx={{ padding: '20px 0' }}>
              <Grid item xs={12} sm={12} className="footerBox">
                <img className="footerIcon" src={icons.glam9WhiteLogo} />
                <Typography
                  sx={{
                    fontSize: '20px',
                    marginTop: 1,
                    color: 'white',
                    fontWeight: '400',
                  }}
                ></Typography>
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: 2.5,
                    gap: 2.5,
                  }}
                  className="footerSocial"
                >
                  <Link to={'https://www.facebook.com/glam9official'}>
                    <img src={icons.footer_fb} />
                  </Link>
                  <Link to={'https://www.instagram.com/glam9.beauty/'}>
                    <img src={icons.instagram} />
                  </Link>
                  <Link
                    to={
                      'https://www.linkedin.com/company/glam9-solutions-private-limited/'
                    }
                  >
                    <img src={icons.linkedinIcon} />
                  </Link>
                  <Link to={'https://x.com/glam9_beauty'}>
                    <img src={icons.twitter} />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} className="footerBox">
                <Typography className="footerHeads">About Glam9</Typography>

                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                >
                  <Link to="/about">About Us</Link>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                >
                  <Link to="/support">Contact Us</Link>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                >
                  <Link to="https://blog.glam9.com">Blog</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.5} className="footerBox">
                <Typography className="footerHeads">For Business</Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                >
                  <Link to="/business">Service For Partners</Link>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                >
                  <Link to="/pricing">Pricing</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2.5} className="footerBox">
                <Typography className="footerHeads">Legal</Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                  className="footerContent"
                >
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                >
                  <Link to="/terms-of-services">Terms of Services</Link>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}
                >
                  <Link to="/refund">Refund & Cancellation Policy</Link>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2.5}
                className="footerBox footerContactInfo"
              >
                <Typography className="footerHeads">
                  Contact Information
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '8px',
                    marginBottom: '10px',
                  }}
                >
                  <img
                    src={icons.footerLocation}
                    alt="Location Icon"
                    style={{ marginRight: '10px' }}
                  />
                  {/* <Typography className="footerAddress" sx={{ color: 'white' }}>
                    Glam9 Solution Pvt. Ltd.
                    <br />
                    205, Iscon Elegance, SG Highway,
                    <br />
                    Service Rd, Prahlad Nagar,
                    <br />
                    Ahmedabad, Gujarat 380015
                  </Typography> */}
                  <Typography className="footerAddress" sx={{ color: 'white' }}>
                    Glam9 Solution Pvt. Ltd.{' '}
                    <span>205, Iscon Elegance, SG Highway,</span>{' '}
                    <span>Service Rd, Prahlad Nagar,</span> Ahmedabad, Gujarat
                    380015
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <img
                    src={icons.sms}
                    alt="Email Icon"
                    style={{ marginRight: '10px' }}
                  />
                  <Typography>
                    <Link
                      to="mailto:inquiry@glam9.com"
                      style={{ color: 'white' }}
                    >
                      inquiry@glam9.com
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider
              className="footerDivider"
              sx={{ backgroundColor: '#454545', marginY: 4 }}
            />
            <Typography align="center" className="footerCopyWrite">
              Copyright © {currentYear.toString() || ''} Glam9 Solution Pvt.
              Ltd.
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Footer;
