export const getUserLocation = (): Promise<{ lat: number; long: number }> => {
  return new Promise((resolve, reject) => {
    console.log('navigator.geolocation', navigator.geolocation);

    let lat = 23.0225;
    let long = 72.5714;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          lat = position.coords.latitude;
          long = position.coords.longitude;
          // console.log('lat ,long', abc, xyz);
          resolve({ lat, long });
        },
        error => {
          // reject(error);
          resolve({ lat, long });
        },
      );
    } else {
      // reject(new Error('Geolocation is not supported by this browser.'));
      resolve({ lat, long });
    }
  });
};

export const validatePhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber.trim()) {
    return 'Please enter phone number';
  }
  if (!/^\d{10}$/.test(phoneNumber)) {
    return 'Phone number should be 10 digits';
  }
  return ''; // No error
};
