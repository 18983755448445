import React, { useEffect, useState } from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import { Container, Grid, Typography, Box } from '@mui/material';
import { images } from '../../../src/Assets/images';
import './Refund.css';
const Refund = () => {
  return (
    <>
      <Grid container spacing={2} className="cmdBanner">
        <Typography variant='h2'>Refund & <span>Cancellation Policy</span></Typography>
      </Grid>
      <Grid container spacing={2} className="cmdContent">
        <Typography variant='h2'>Introduction</Typography>
        <Typography variant='body1'>At Glam9, we serve as a digital marketplace connecting customers with salons, spas, and beauticians. As an intermediary platform, Glam9 facilitates bookings and transactions between customers and service vendors. This policy outlines the terms and conditions governing refunds and cancellations on our platform.</Typography>
        <Typography variant='h3'>1. Refund Policy</Typography><br></br><br></br>
        <Typography variant='h4'>1.1 Vendor Responsibility</Typography>
        <Typography variant='body1'>Refund eligibility and conditions are primarily determined by the individual policies of our partnered service vendors. Customers are encouraged to carefully review the vendor's refund policy before confirming any booking.</Typography>
        <Typography variant='h4'>1.2 Service-Related Refunds</Typography>
        <Typography variant='body1'>If a booked service is not delivered as promised, is unsatisfactory, or if there is a significant discrepancy in the service received, customers are required to report the issue to Glam9 within 48 hours of the service completion.</Typography>
        <Typography variant='h4'>1.3 Mediation by Glam9</Typography>
        <Typography variant='body1'>While Glam9 does not directly process refunds, we act as a mediator between the customer and the vendor to facilitate a fair resolution. The final decision regarding refunds lies with the respective vendor.</Typography>
        <Typography variant='h4'>1.4 Refund Processing Time</Typography>
        <Typography variant='body1'>For approved refunds, the processing timeline will depend on the vendor's policy. Typically, refunds are processed within <span>7-14 business days</span> to the original payment method.</Typography>
        <Typography variant='h4'>1.5 Non-Refundable Scenarios</Typography>
        <ul className='refundList'>
          <li>Failure to attend an appointment (No-show).</li>
          <li>Cancellations made outside the vendor's specified window.</li>
          <li>Services already rendered.</li>
          <li>Any violation of vendor or Glam9 platform policies.</li>
          </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent">
        <Typography variant='h3'>2. Cancellation Policy</Typography><br></br><br></br>
        <Typography variant='h4'>2.1 Vendor-Specific Rules</Typography>
        <Typography variant='body1'>Each service vendor maintains their own cancellation policy, including timelines and penalties. These terms are displayed on the service booking page, and customers are advised to review them before finalizing their bookings.</Typography>
        <Typography variant='h4'>2.2 Customer-Initiated Cancellations</Typography>
        <Typography variant='body1'><span>Timely Cancellations:</span> Cancellations made within the vendor-specified window may qualify for a full or partial refund, subject to vendor terms.</Typography>
        <Typography variant='body1'><span>Late Cancellations: </span> Cancellations made after the allowed window may result in penalties or forfeiture of the payment.</Typography>
        <Typography variant='h4'>2.3 Vendor-Initiated Cancellations</Typography>
        <ul className='refundList'>
          <li>If a vendor cancels a confirmed appointment, customers are entitled to a full refund or the option to reschedule the appointment.</li>
          <li>Glam9 will assist in coordinating refunds or rescheduling where applicable.</li>
        </ul>
        <Typography variant='h4'>2.4 Glam9 Intervention</Typography>
        <Typography variant='body1'>In case of disputes arising from cancellations, customers can escalate the matter to Glam9 support for further investigation and mediation.</Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent">
        <Typography variant='h3'>3. Dispute Resolution</Typography><br></br><br></br>
        <ul className='refundList'>
          <li>Customers can raise a dispute via [Insert Support Email] or through the Glam9 platform's support portal.</li>
          <li>Disputes must include relevant details such as booking ID, vendor details, issue description, and supporting evidence (e.g., photos, chat transcripts).</li>
          <li>Glam9 will assess the issue objectively and facilitate communication between the customer and vendor to reach a fair resolution.</li>
          <li>Final decisions on refunds and cancellations remain subject to vendor policies.</li>
        </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent">
        <Typography variant='h3'>4. Limitation of Liability</Typography><br></br><br></br>
        <ul className='refundList'>
          <li>Glam9 acts solely as a facilitator and intermediary platform.</li>
          <li>The final responsibility for service quality, refunds, and cancellations rests with the individual service vendors.</li>
          <li>Glam9 shall not be held liable for financial or non-financial losses arising from vendor non-compliance or disputes.</li>
        </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent">
        <Typography variant='h3'>5. Platform Integrity and Misuse</Typography><br></br><br></br>
        <ul className='refundList'>
          <li>Misuse of the refund and cancellation process, including fraudulent claims or repeated cancellations, may result in account suspension or termination.</li>
          <li>Glam9 reserves the right to investigate and take appropriate action in case of policy violations.</li>
        </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent">
        <Typography variant='h3' sx={{marginBottom: '10px'}}>6. Contact Us</Typography><br></br><br></br>
        <Typography variant='body1'>For assistance regarding refunds and cancellations, customers may contact us:</Typography>
        <ul className='refundList'>
          <li><span>Email:</span> [Insert Support Email]</li>
          <li><span>Phone:</span> [Insert Support Phone Number]</li>
          <li><span>Support Portal:</span> [Insert Support Portal Link]</li>
        </ul>
        <Typography variant='body1' sx={{marginBottom:'150px'}}>We value your trust in Glam9 and strive to ensure a seamless experience for both customers and service vendors. Please review our policies carefully before proceeding with any bookings.</Typography>
      </Grid>
      </>
  );
};

export default Refund;