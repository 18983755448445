import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  RadioGroup,
  MenuItem,
  ImageListItem,
  ImageListItemBar,
  Box,
  CardMedia,
  Paper,
  fabClasses,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MuiSliderMax from '@mui/material/Slider';
import MuiSliderMin from '@mui/material/Slider';
import Slider from 'react-slick';
import catSlider from 'react-slick';
import { images } from '../../Assets/images';
import { icons } from '../../Assets/icons';
import './Home.css';
import { useAppTranslation } from '../../Utils/i18n';
import CurrencyRupeeIcon from '@mui/icons-material/FilterList';
import toast from 'react-hot-toast';
import WcIcon from '@mui/icons-material/Wc';
import salon from '@mui/icons-material/EventSeat';
import InputAdornment from '@mui/material/InputAdornment';
import AppDownloadSection from './AppDownloadSection/AppDownloadSection';
import Offersection from './Offersection/Offersection';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Rating from '@mui/material/Rating';
import { LoadScript } from '@react-google-maps/api';
import { Autocomplete as MUIAutocomplete } from '@mui/material';

// Interface
import {
  CategoryList,
  DropdownListResponse,
  SalonType,
  ApiResponse,
} from '../../../src/api/types/common';
import {
  Dashboard,
  VendorList,
  VendorData,
  BannerResponse,
  BannerData,
  DashboardResponse,
  getVendorListingReqInterface,
  offersDataResponse,
} from '../../../src/api/types/dashboard';

// Context
import useHomeContext from '../../../src/hooks/useHomeContext';
import useAppContext from '../../../src/hooks/useAppContext';
import { getUserLocation } from '../../../src/Common/CommonFunctions/common';

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      src={icons.right}
      alt="Next"
    />
  );
}
function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      src={icons.left}
      alt="Prev"
    />
  );
}

const settings = {
  dots: false,
  arrows: true,
  autoplay: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [
    {
      breakpoint: 1025,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 899,
      settings: {
        slidesToShow: 2,
        centerMode: false, // Ensures no side items are shown
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        // centerMode: true, // Ensures no side items are shown
      },
    },
  ],
};

const catSettings = {
  dots: false,
  arrows: false,
  autoplay: true,
  infinite: true,
  speed: 3000,
  slidesToShow: 13,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1367,
      settings: { slidesToShow: 10 },
    },
    {
      breakpoint: 1024,
      settings: { slidesToShow: 7 },
    },
    {
      breakpoint: 800,
      settings: { slidesToShow: 6 },
    },
    {
      breakpoint: 600,
      settings: { slidesToShow: 3 },
    },
  ],
};

const VendorItem: React.FC<{ item: VendorData; gotoShop: Function }> =
  React.memo(({ item, gotoShop }) => {
    return (
      <div className="ShopContainer">
        <div className="imageSaloon">
          <img
            src={
              item?.vendor_media?.media_name
                ? item.vendor_media.media_name
                : images.glam9512
            }
            alt={item.business_name}
            className={
              item?.vendor_media?.media_name
                ? 'featureImage'
                : 'featureImage smallG9'
            }
          />
        </div>
        <div className="contentSaloon">
          <Typography
            variant="body1"
            className="boxTitle"
            style={{
              fontSize: '18px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {item.salon_type === '5' && (
              <img
                className="businessListImage"
                src={images.crown}
                alt="Crown"
                onError={() => 'image not exists'}
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 5,
                  position: 'relative',
                  // top: 8,
                }} // Set size and margin
              />
            )}
            {item.business_name}
          </Typography>
          <Typography
            className="location"
            variant="body2"
            sx={{
              marginTop: '0px',
              fontSize: '14px',
              color: 'rgba(35, 31, 32, 1)',
              opacity: '30%',
              fontWeight: '400',
              height: '25px',
            }}
          >
            {item.vendor_address?.city_name}, {item.vendor_address?.state_name}
          </Typography>
          <div>
            <Box
              // marginTop="15px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              // bgcolor='red'
            >
              <Typography
                className="ratingContent"
                variant="body2"
                style={{ color: 'rgba(35, 31, 32, 1)' }}
              >
                {item.rating}{' '}
                <img className="rating" src={icons.rating} alt="Rating" />
              </Typography>
              <CardMedia
                component="img"
                className="searchImage"
                alt="Vector"
                src={icons.recommendedSearch}
                sx={{
                  width: 40,
                  height: 40,
                  // transform: 'translate(50%, 50%)', // Adjust to fit within the box
                  cursor: 'pointer',
                }}
                onClick={() => {
                  gotoShop(item?.vendor_detail_id);
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  });

const VendorListComponent: React.FC<{
  data: VendorList;
  index: number;
  gotoShop: Function;
  isSearch: boolean;
}> = React.memo(({ data, index, gotoShop, isSearch }) => {
  const itemLength = data.data.length;

  const [activeIndex, setActiveIndex] = useState(0);

  const shopSettings = {
    dots: false,
    arrows: itemLength > 4, // Show arrows only if items are more than 4
    autoplay: false,
    infinite: itemLength > 4,
    speed: 500,
    slidesToShow: 4,
    centerMode: false,
    slidesToScroll: 1,
    variableWidth: false,
    afterChange: (current: any) => setActiveIndex(current),
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 1366,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 1280,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          autoplay: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: 1.25,
          slidesToShow: 1,
          autoplay: false,
          arrows: false,
          dots: false,          
        },
      },
    ],
  };
  const navigate = useNavigate();
  function gotoSeeAll() {
    console.log('data', data);
    navigate('/search', { state: { data } });
  }
  return (
    // {itemLength > 0 && ()}
    <Grid
      item
      xs={12}
      key={index}
      className="sliderContainer"
      sx={{ textAlign: 'center', marginTop: 1, marginBottom: 0, paddingTop: 0 }}
    >
      {/* <Box
        width="48%"
        display="flex"
        sx={{ float: 'left' }}
        justifyContent="left"
      >
        <Typography
          variant="h4"
          className="salonBoxTitle"
          sx={{
            fontSize: { xs: '18px', sm: '18px', xl: '25px' },
            fontWeight: '700',
            color: 'rgba(35, 31, 32, 1)',
            marginTop: '0rem',
            marginBottom: '1.5rem',
            width: { xs: 'auto', sm: 'auto' },
            height: '50px',
            textAlign: 'left',
            borderRadius: '500px',
            paddingTop: { xs: '20px', sm: '12px', xl: '10px' },
          }}
        >
          {data.title}
        </Typography>
      </Box> */}
      {/* <Box
        width="50%"
        display="flex"
        sx={{ position: 'relative' }}
        justifyContent="right"
      >
        <img className="seeAll" src={icons.seeAll} onClick={gotoSeeAll} />
      </Box> */}
      <div className="titleWrap">
        <h2>{data.title}</h2>
        {data.data.length > 4 && (
          <Button onClick={gotoSeeAll} className="seeAll">
            See All
          </Button>
        )}
      </div>
      <Box
        className="homeShop"
        sx={{
          position: 'relative',
          // overflow: 'hidden',
          minHeight: '200px',
          width: 'calc(100% + 16px)',
        }}
      >
        <Slider {...shopSettings}>
          {data.data.map((item, idx) => (
            <div key={idx}>
              <Grid container spacing={2} className="recommendedSection">
                <Grid
                  item
                  xs={12}
                  className={`slide ${
                    index === idx ? 'slick-active' : 'slick-inactive'
                  }`}
                  onClick={() => gotoShop(item?.vendor_detail_id)}
                >
                  <VendorItem key={idx} item={item} gotoShop={gotoShop} />
                </Grid>
              </Grid>
            </div>
          ))}
        </Slider>
      </Box>
    </Grid>
  );
});

interface DropdownOption {
  name: string;
  placeId: string | undefined; // Allow undefined
  lat: number;
  lng: number;
}

const Home: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [dashboardDataReq, setDashboardDataReq] =
    useState<getVendorListingReqInterface>({
      search_keyword: '',
      sort_by: 0,
      salon_type: 0,
      category_id: 0,
      professional_keyword: '',
      gender_type: 0,
      service_percentage: '',
      latitude: 0,
      longitude: 0,
    });
  const [searchError, setSearchError] = useState('');
  const [dashboardData, setDashboardData] = useState<Dashboard>();
  const [reviewData, setReviewData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<CategoryList[]>([]);
  const [salonTypeData, setSalonTypeData] = useState<SalonType[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [bannerData, setBannerData] = useState<BannerData[]>([]);

  const [vendorOffersData, setVendorOffersData] = useState<any>({});

  console.log('vendorOffersData -->>', vendorOffersData?.salon);

  const isMobile = useMediaQuery('(max-width: 600px)');

  const gotoShop = useCallback(
    (vendor_detail_id: string) => {
      localStorage.setItem('shopID', vendor_detail_id);
      navigate('/shop');
    },
    [navigate],
  ); // Only change if navigate changes

  const getInitials = (name: string) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    let initials =
      nameParts.length > 1
        ? nameParts[0][0] + nameParts[nameParts.length - 1][0]
        : nameParts[0][0]; // Use only the first initial if there's one word
    if (name == 'Prakash Parekh (Bholabhai)') {
      initials = 'PP';
    }
    return initials.toUpperCase();
  };

  const bannerDataAction = (bannerData: any) => {
    if (bannerData.banner_type === '2') {
      window.location.href = bannerData.banner_url;
    }
    if (bannerData.banner_type === '1') {
      localStorage.setItem('shopID', bannerData.vendor_detail_id);
      navigate('/shop');
    }
  };

  /* price data */
  const [value, setValue] = useState([0, 25000]);
  const [priceRange, setPriceRange] = useState<number[]>([0, 25000]);
  const [priceRangeError, setPriceRangeError] = useState<boolean>(false);
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!dashboardDataReq.category_id) {
      setPriceRangeError(true);
      return;
    }

    setPriceRange(newValue as number[]);
    // Use the newValue directly to update the dashboardDataReq
    setDashboardDataReq(prevState => ({
      ...prevState,
      min_price: (newValue as number[])[0],
      max_price: (newValue as number[])[1],
    }));
    setPriceRangeError(false);
  };

  function gotoSalonOffers() {
    alert('home');
    navigate('/salon-offers');
  }
  function goToVendorOffers(offerType: string) {
    navigate('/vendor-offers', { state: { offerType: offerType } });
  }

  const valuetext = (value: any) => `₹${value}`;
  const minMarks = [
    {
      value: 0,
      label: '₹0',
    },
    {
      value: 25000,
      label: '₹25000',
    },
  ];
  /* price data */

  const searchData = () => {
    let searchReq: any = dashboardDataReq;
    if (
      !searchReq?.search_keyword &&
      !searchReq?.category_id &&
      !searchReq?.sort_by &&
      !searchReq?.salon_type &&
      !searchReq?.min_price &&
      !searchReq?.max_price &&
      !searchReq?.gender_type &&
      !searchReq?.latitude &&
      !searchReq?.longitude
    ) {
      setSearchError('Please select any one Option');
      return;
    } else {
      //  service_percentage: `${priceRange[0]}-${priceRange[1]}`,
      navigate('/search', { state: { searchReq } });
      //vendorListingFilter(dashboardDataReq);
      // setSearchError('');
      // setIsSearch(true);
    }
  };
  const {
    getCityListFunc,
    getBannerImagesFunc,
    getDropdownListFunc,
    getDashboardDataFunc,
    getCategoryListFunc,
    getCategoryFunc,
    getVenorListingFunc,
    getVendorOffersFunc,
  } = useAppContext();

  // Dashboard Data
  const getDashboardData = useCallback(async () => {
    const { lat, long } = await getUserLocation();

    const result: DashboardResponse = await getDashboardDataFunc(lat, long);
    if (result === null) {
      toast.error('Failed to fetch city data.');
      return;
    }
    if (result?.status_code === 200) {
      setDashboardData(result.data);
      setReviewData(result.data.review);
    } else {
      toast.error(result?.message);
    }
  }, [getDashboardDataFunc]);

  // Vendor Listing Filter
  const vendorListingFilter = useCallback(
    async (data: getVendorListingReqInterface) => {
      const result: any = await getVenorListingFunc(data);
      if (result === null) {
        toast.error('Failed to filter data.');
        return;
      }
      if (result?.status_code === 200 && result?.data) {
        let dataObject: any = {
          vendor_list: [
            {
              title: `${result?.data?.total} Venues nearby`,
              data: result?.data?.data,
            },
          ],
        };
        setDashboardData(dataObject);
      } else {
        let dataObject: any = {
          vendor_list: [
            {
              title: `No Records Found`,
              data: [],
            },
          ],
        };
        setDashboardData(dataObject);
        toast.error(result?.message);
      }
    },
    [getVenorListingFunc],
  );

  const clearSearch = useCallback(() => {
    setDashboardDataReq({
      ...dashboardDataReq,
      search_keyword: '',
      sort_by: 0,
      salon_type: 0,
      category_id: 0,
      professional_keyword: '',
      gender_type: 0,
      service_percentage: '',
      latitude: 0,
      longitude: 0,
    });
    getDashboardData();
    setSearchError('');
    setIsSearch(false);
  }, [getDashboardData, dashboardDataReq]);

  useEffect(() => {
    // Categories
    const fetchCategoryList = async () => {
      const categoryDataResult: ApiResponse<CategoryList> | null =
        // await getCategoryListFunc(1);
        await getCategoryFunc(1);
      if (categoryDataResult === null) {
        toast.error('Failed to fetch city data.');
        return;
      }
      if (categoryDataResult?.status_code === 200) {
        setCategoryData(categoryDataResult.data);
      } else {
        toast.error(categoryDataResult?.message);
      }
    };
    fetchCategoryList();
    // Banner Records
    const fetchBannersData = async () => {
      const bannerDataResult: BannerResponse = await getBannerImagesFunc();
      if (bannerDataResult === null) {
        toast.error('Failed to fetch city data.');
        return;
      }
      if (bannerDataResult?.status_code === 200) {
        setBannerData(bannerDataResult.data);
      } else {
        toast.error(bannerDataResult?.message);
      }
    };
    fetchBannersData();
    // Dropdown List
    const fetchDropdownList = async () => {
      const dropdownListData: DropdownListResponse =
        await getDropdownListFunc();
      if (dropdownListData === null) {
        toast.error('Failed to fetch city data.');
        return;
      }
      if (dropdownListData?.status_code === 200) {
        setSalonTypeData(dropdownListData.data.salon_types);
      } else {
        toast.error(dropdownListData?.message);
      }
    };
    fetchDropdownList();

    // Vendor Offers List
    const fetchVendorOffersList = async () => {
      const vendorOffersData: offersDataResponse = await getVendorOffersFunc();

      console.log('vendorOffersData', vendorOffersData);

      if (vendorOffersData === null) {
        toast.error('Failed to fetch city data.');
        return;
      }
      if (vendorOffersData?.status_code === 200) {
        setVendorOffersData(vendorOffersData.data);
      } else {
        toast.error(vendorOffersData?.message);
      }
    };
    fetchVendorOffersList();
    getDashboardData();
  }, [
    getCityListFunc,
    getBannerImagesFunc,
    getDropdownListFunc,
    getDashboardData,
    getCategoryListFunc,
    getVendorOffersFunc,
  ]);

  const [dashboardDataReq33, setDashboardDataReq33] =
    useState<getVendorListingReqInterface>({
      search_keyword: '',
      sort_by: 0,
      salon_type: 0,
      category_id: 0,
      professional_keyword: '',
      gender_type: 0,
      service_percentage: '',
      latitude: 0,
      longitude: 0,
    });

  function gotoSearch(id: any) {
    //searchReq?.category_id
    setDashboardDataReq33(prevState => {
      const updatedState = { ...prevState, category_id: id };
      //console.log('before', updatedState); // Logs the updated state
      let searchReq: any = updatedState;
      navigate('/search', { state: { searchReq } });
      return updatedState;
    });
  }

  // Banner Images Part
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAdvancedFilterChecked, setIsAdvancedFilterChecked] = useState(false);
  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % bannerData.length);
  };
  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? bannerData.length - 1 : prevIndex - 1,
    );
  };
  // User Lat Long
  const [location, setLocation] = useState<any>(null);
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
        },
        error => {
          console.error('Error fetching location: ', error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  // map regarding code

  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  // Initialize the Google Maps Autocomplete API
  const loadGoogleMapsScript = (): google.maps.places.AutocompleteService => {
    return new window.google.maps.places.AutocompleteService();
  };

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    setInputValue(value);

    if (value) {
      const service = loadGoogleMapsScript();
      service.getQueryPredictions(
        { input: value },
        (
          predictions: google.maps.places.QueryAutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setDropdownOptions(
              predictions
                .filter(prediction => prediction.place_id)
                .map(prediction => ({
                  name: prediction.description,
                  placeId: prediction.place_id,
                  lat: 0,
                  lng: 0,
                })),
            );
          } else {
            setDropdownOptions([]);
          }
        },
      );
    }
  };

  const handlePlaceSelect = (value: string) => {
    const selectedOption = dropdownOptions.find(
      option => option.name === value,
    );
    if (selectedOption && selectedOption.placeId) {
      // Create a dummy div as the container
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );

      service.getDetails(
        { placeId: selectedOption.placeId },
        (place: google.maps.places.PlaceResult | null) => {
          if (place && place.geometry && place.geometry.location) {
            const location = place.geometry.location;
            const lat = location.lat();
            const lng = location.lng();

            setDashboardDataReq({
              ...dashboardDataReq,
              latitude: lat,
              longitude: lng,
            });
          }
        },
      );
    }
  };

  const [loadScript, setLoadScript] = useState(false);
  const [latLng, setLatLng] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });

  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false); // Track script load state

  useEffect(() => {
    if (!location) {
      getLocation();
    }
  }, [location, isScriptLoaded]);

  const handleScriptLoad = () => {
    setIsScriptLoaded(true); // Set script as loaded
  };

  return (
    <Box
      className="homepageClasses"
      sx={{
        width: '100%',
        padding: '0px',
        background: '#f6f6f6',
      }}
    >
      <Box sx={{ padding: '0px', textAlign: 'center' }}>
        <Typography className="mainTitle">
          <h1 className="home_title"> India's #1 Super Magic Platform</h1>
          <span className="home_subtitle">
            To book your local beauty and wellness services
          </span>
        </Typography>
      </Box>

      <Grid container spacing={3} className="filterContainer">
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: '4.375rem',
            paddingBottom: isMobile ? '20px' : '0rem',
          }}
        >
          <Grid container spacing={2} className="searchBy">
            <Grid item xs={12} sm={4} md={3} lg={2.5}>
              <TextField
                // placeholder={t('All Treatments and Venues...')}
                placeholder={'All Treatments and Venues'}
                variant="outlined"
                value={dashboardDataReq?.search_keyword}
                className="searchVanue"
                onChange={e => {
                  setDashboardDataReq({
                    ...dashboardDataReq,
                    search_keyword: e.target.value,
                  });
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    searchData();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={icons.service} alt="Service Icon" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  fontWeight: '400',
                  color: 'rgba(35, 31, 32, 1)',
                }}
              />
            </Grid>

            {/* <Grid
              item
              xl={2.5}
              lg={2.5}
              xs={12}
              sm={4}
              md={isScriptLoaded ? 3 : 0}
              className="locationSearch"
            >
              <FormControl
                variant="outlined"
                fullWidth
                className="location-input"
              >
                {process.env.REACT_APP_GOOGLE_MAPS_API_KEY && (
                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    libraries={['places']}
                    onLoad={handleScriptLoad}
                    loadingElement={<div style={{ display: 'none' }} />}
                  >
                    {isScriptLoaded && (
                      <div>
                        <MUIAutocomplete
                          freeSolo
                          value={inputValue}
                          onChange={(event, newValue) => {
                            setInputValue(newValue || '');
                            handlePlaceSelect(newValue || '');
                          }}
                          onInputChange={handleInputChange}
                          options={dropdownOptions.map(option => option.name)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              className="filterCategory"
                              placeholder="Enter a location"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={icons.location}
                                      alt="Service Icon"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                  </LoadScript>
                )}
              </FormControl>
            </Grid> */}

            <Grid
              item
              xl={2.5}
              lg={2.5}
              xs={12}
              sm={4}
              md={isScriptLoaded ? 3 : 0}
              className="locationSearch"
            >
              <FormControl
                variant="outlined"
                fullWidth
                className="location-input"
              >
                {process.env.REACT_APP_GOOGLE_MAPS_API_KEY && (
                  <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    libraries={['places']}
                    onLoad={handleScriptLoad}
                    loadingElement={<div style={{ display: 'none' }} />}
                  />
                )}
                <MUIAutocomplete
                  freeSolo
                  value={inputValue}
                  onChange={(event, newValue) => {
                    setInputValue(newValue || '');
                    if (isScriptLoaded) {
                      handlePlaceSelect(newValue || '');
                    }
                  }}
                  onInputChange={handleInputChange}
                  options={
                    isScriptLoaded
                      ? dropdownOptions.map(option => option.name)
                      : []
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      className="filterCategory"
                      placeholder="Enter a location"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={icons.locationBlack}
                              alt="Service Icon"
                              style={{ marginLeft: '10px' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2.5}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  className="filterCategory"
                  IconComponent={CurrencyRupeeIcon}
                  value={dashboardDataReq?.category_id}
                  onChange={(e: any) => {
                    if (e?.target?.value) {
                      setDashboardDataReq({
                        ...dashboardDataReq,
                        category_id: parseInt(e?.target?.value),
                      });
                      setPriceRangeError(false);
                    }
                  }}
                  MenuProps={{
                    sx: {
                      '& .MuiSelect-icon': {
                        transform: 'none', // Prevent flipping
                      },
                    },
                    disableScrollLock: true,
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Control dropdown height
                      },
                    },
                  }}
                >
                  <MenuItem value="0">
                    {/* <em>{t('Category')}</em> */}
                    <em>{'Category'}</em>
                  </MenuItem>
                  {categoryData.map((row: CategoryList) => (
                    <MenuItem key={row?.id} value={row?.id}>
                      {row?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2.5}>
              <FormControl variant="outlined" fullWidth className="salonType">
                <Select
                  IconComponent={CurrencyRupeeIcon}
                  className="filterCategory"
                  value={dashboardDataReq?.sort_by}
                  onChange={(e: any) => {
                    setDashboardDataReq({
                      ...dashboardDataReq,
                      sort_by: parseInt(e.target.value),
                    });
                    if (e.target.value === '3') {
                      setDashboardDataReq({
                        ...dashboardDataReq,
                        latitude: location?.lat ?? 0,
                        longitude: location.lng ?? 0,
                        sort_by: parseInt(e.target.value),
                      });
                    }
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Control dropdown height
                      },
                    },
                  }}
                >
                  <MenuItem value="0">
                    <em>{'Sort By'}</em>
                  </MenuItem>
                  <MenuItem value="3">{'Nearest'}</MenuItem>
                  <MenuItem value="1">{'Top Rated'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Button
                onClick={() =>
                  setIsAdvancedFilterChecked(prevState => !prevState)
                }
                className="filter btn btn-primary"
                sx={{ color: 'rgba(35, 31, 32, 1)' }}
              >
                <img
                  src={icons.homeFilter}
                  alt="Filter"
                  style={{ top: '0px' }}
                />
                &nbsp;
                <span>Advanced Filter</span>{' '}
              </Button>
            </Grid>
          </Grid>
          {isAdvancedFilterChecked && (
            <Grid container spacing={2} className="searchBy advance">
              <Grid item xs={0} sm={0} md={1} className="extraSpaceFilter" />
              <Grid item xs={12} sm={6} md={2}>
                <Box className="minPrice" sx={{ width: 'auto' }}>
                  <label>Price Range</label>
                  <MuiSliderMin
                    getAriaLabel={() => 'Price range'}
                    value={priceRange}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    marks={minMarks}
                    min={0}
                    max={25000}
                    getAriaValueText={(value: any) => `${value}`}
                  />
                </Box>
                {priceRangeError && (
                  <Typography
                    color="error"
                    variant="body2"
                    sx={{ marginTop: 1 }}
                  >
                    Please select a category first.
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                lg={2.5}
                className="businessTypeContainer"
              >
                <FormControl variant="outlined" fullWidth>
                  <Select
                    labelId="business-type-label"
                    IconComponent={salon}
                    className="filterCategory"
                    value={dashboardDataReq?.salon_type}
                    onChange={(e: any) => {
                      setDashboardDataReq({
                        ...dashboardDataReq,
                        salon_type: parseInt(e.target.value),
                      });
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Control dropdown height
                        },
                      },
                    }}
                  >
                    <MenuItem value="0">
                      {/* <em>{t('Business Type')}</em> */}
                      <em>{'Business Type'}</em>
                    </MenuItem>
                    {salonTypeData.map((row: any) => (
                      <MenuItem key={row?.id} value={row?.id}>
                        {row?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    IconComponent={WcIcon}
                    className="filterCategory"
                    value={dashboardDataReq?.gender_type}
                    onChange={(e: any) => {
                      setDashboardDataReq({
                        ...dashboardDataReq,
                        gender_type: parseInt(e.target.value),
                      });
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Control dropdown height
                        },
                      },
                    }}
                  >
                    <MenuItem value="0">
                      {/* <em>{t('Gender Type')}</em> */}
                      <em>{'Gender Type'}</em>
                    </MenuItem>

                    <MenuItem value="1">Male</MenuItem>
                    <MenuItem value="2">Female</MenuItem>
                    <MenuItem value="3">UniSex</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          )}
          <Grid container spacing={2} className="searchBy">
            <Typography
              variant="caption"
              color="error"
              align="center"
              sx={{ margin: '0 auto', marginTop: '30px', marginBottom: '20px' }}
            >
              {searchError}
            </Typography>
          </Grid>
          <Box sx={{ textAlign: 'center', marginTop: { xl: 1, lg: 0, md: 0 } }}>
            <Button
              className={'searchButton'}
              onClick={() => {
                searchData();
              }}
            >
              <h2>Search</h2>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Slider className="catSlider" {...catSettings}>
        {categoryData.map((row: CategoryList) => (
          <>
            <div
              className="catImg"
              style={{
                height: '60px',
                display: 'flex',
                background: 'transparent',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <img
                src={row?.logo_link}
                alt={row?.id.toString()}
                onClick={() => gotoSearch(row.id)}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div
              className="catName"
              style={{
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 8px',
              }}
            >
              <Tooltip title={row?.name} arrow>
                <Typography
                  className="catsliderText"
                  variant="body1"
                  onClick={() => gotoSearch(row.id)}
                  sx={{
                    fontSize: '15px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                >
                  {row?.name}
                </Typography>
              </Tooltip>
            </div>
          </>
        ))}
      </Slider>
      <div className="offer">
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
            marginBottom: 0,
            paddingTop: { xs: 2.5, sm: 5 },
          }}
        >
          {/* <Box
            width="48%"
            display="flex"
            sx={{ float: 'left' }}
            justifyContent="left"
          >
            <Typography
              variant="h4"
              className="salonBoxTitle"
              sx={{
                fontSize: { xs: '20px', sm: '20px', xl: '35px' },
                fontWeight: '600',
                lineHeight: '52px',
                color: 'rgba(35, 31, 32, 1)',
                marginTop: '0rem',
                width: { xs: '200px', sm: '250px' },
                height: '50px',
                textAlign: 'left',
                borderRadius: '500px',
                
              }}
            >
              Salon's Offers
            </Typography>
          </Box> */}
          {/* <Box
            width="50%"
            display="flex"
            onClick={gotoSalonOffers}
            sx={{ position: 'relative' }}
            justifyContent="right"
          >
            <img className="seeAll" src={icons.seeAll} />
          </Box> */}
          <div className="titleWrap">
            <h2>Salon's Offers</h2>
            <button
              className="seeAll"
              onClick={() => goToVendorOffers('salon')}
            >
              See All
            </button>
          </div>
          <Box
            className="homeShop"
            sx={{
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
            }}
          ></Box>
        </Grid>
        <Grid container spacing={2}>
          {Array.isArray(vendorOffersData?.salon) &&
            (vendorOffersData?.salon || []).map((offer: any, index: number) => (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index + 1}>
                <Offersection data={offer} />
              </Grid>
            ))}

          {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Offersection image={images.koreanClassSkinTreatment} />
          </Grid> */}
        </Grid>
      </div>

      {/* <Grid container spacing={2} marginTop={10} className="Home">
        <Grid item xs={12}>
          <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
            <Box
              sx={{
                display: 'flex',
                transition: 'transform 0.5s ease-in-out',
                transform: `translateX(-${currentIndex * 100}%)`,
              }}
            >
              {bannerData.map((bannerObject: any, index: number) => (
                <Box
                  key={index}
                  component="img"
                  onClick={() => {
                    bannerDataAction(bannerObject);
                  }}
                  src={bannerObject.media_name}
                  alt={`Banner ${index + 1}`}
                  sx={{
                    width: '100%',
                    flexShrink: 0,
                    height: '18rem',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    objectPosition: 'center',
                  }}
                />
              ))}
            </Box>
            <Button
              onClick={handlePrev}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '1.25rem',
                transform: 'translateY(-50%)',
                color: 'white',
              }}
            >
              <img alt="leftArrow" src={icons.left} />
            </Button>
            <Button
              onClick={handleNext}
              sx={{
                position: 'absolute',
                top: '50%',
                right: '1.25rem',
                transform: 'translateY(-50%)',
                color: 'white',
              }}
            >
              <img alt="rightArrow" src={icons.right} />
            </Button>
          </Box>
        </Grid>
      </Grid> */}

      {/* <Grid container marginTop={10} className="Home serviceList"></Grid> */}

      <Grid
        container
        spacing={3}
        marginTop={10}
        marginBottom={20}
        className="Home"
      >
        {dashboardData &&
        dashboardData?.vendor_list &&
        dashboardData?.vendor_list?.length > 0 ? (
          <>
            {dashboardData.vendor_list
              .slice(0, 2)
              .map((data: VendorList, index: number) =>
                data && data.data.length > 0 ? (
                  <VendorListComponent
                    key={index}
                    data={data}
                    index={index}
                    gotoShop={gotoShop}
                    isSearch={isSearch}
                  />
                ) : (
                  <></>
                ),
              )}
          </>
        ) : (
          ''
        )}
      </Grid>

      <div className="offer">
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
            marginBottom: 0,
            paddingTop: { xs: 2.5, sm: 5 },
          }}
        >
          {/* <Box
            width="48%"
            display="flex"
            sx={{ float: 'left' }}
            justifyContent="left"
          >
            <Typography
              variant="h4"
              className="salonBoxTitle"
              sx={{
                fontSize: { xs: '20px', sm: '20px', xl: '25px' },
                fontWeight: '700',
                color: 'rgba(35, 31, 32, 1)',
                marginTop: '0rem',
                width: { xs: '200px', sm: '250px', xl: '100%' },
                height: '50px',
                textAlign: 'left',
                borderRadius: '500px',
                paddingTop: { xs: '20px', sm: '12px', xl: '10px' },
              }}
            >
              Professional's Offers
            </Typography>
          </Box>
          <Box
            width="50%"
            display="flex"
            sx={{ position: 'relative' }}
            justifyContent="right"
            onClick={gotoProfOffers}
          >
            <img className="seeAll" src={icons.seeAll} />
          </Box> */}
          <div className="titleWrap">
            <h2>Professional's Offers</h2>
            {vendorOffersData?.professional?.length >= 2 && (
              <button
                className="seeAll"
                onClick={() => goToVendorOffers('professional')}
              >
                See All
              </button>
            )}
          </div>
          <Box
            className="homeShop"
            // onClick={goToVendorOffers}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
            }}
          ></Box>
        </Grid>
        <Grid container spacing={3}>
          {Array.isArray(vendorOffersData?.professional) &&
            (vendorOffersData?.professional || []).map(
              (offer: any, index: number) => (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index + 1}>
                  <Offersection data={offer} />
                </Grid>
              ),
            )}
          {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Offersection image={images.shivaniVyas} />
          </Grid> */}
        </Grid>
      </div>

      <Grid
        container
        spacing={3}
        marginTop={10}
        marginBottom={20}
        className="Home professional"
      >
        {dashboardData &&
        dashboardData?.vendor_list &&
        dashboardData?.vendor_list?.length > 0 ? (
          <>
            {dashboardData.vendor_list
              .slice(2, 6)
              .map((data: VendorList, index: number) =>
                data && data.data.length > 0 ? (
                  <VendorListComponent
                    key={index}
                    data={data}
                    index={index}
                    gotoShop={gotoShop}
                    isSearch={isSearch}
                  />
                ) : (
                  <></>
                ),
              )}
          </>
        ) : (
          ''
        )}
      </Grid>

      {/* subscription section */}
      <AppDownloadSection />

      <Grid
        container
        className="testimonialContainer"
        sx={{
          marginTop: { xs: 5, md: 0 },
          width: 'auto',
          padding: { xl: '100px', xs: 0, sm: 1, md: 1.5, lg: '100px' },
          marginBottom: { xs: 4, md: 4, xl: 4 },
          paddingBottom: { xs: 4, md: 4, lg: 10, xl: 10 },
        }}
      >
        <Grid
          className="testimonial"
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: { xs: 4, md: 4, xl: 0 },
          }}
        >
          <Box
            className="testimonialFirst"
            sx={{
              width: '100%',
              height: 'auto', // Adjust height for responsiveness
              position: 'relative',
              textAlign: 'left',
              fontSize: '1rem',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            {reviewData &&
            reviewData &&
            reviewData.length > 0 &&
            reviewData[0] &&
            reviewData[0].data ? (
              <Box
                className="testimonialSecond"
                // sx={{
                //   position: 'relative',
                //   top: { xs: '2.5rem', md: '3rem' },
                //   left: { xs: '1.25rem', md: '0.1rem' },
                //   width: '100%',
                //   padding: { xs: 2, md: 0 },
                //   minHeight: '35.125rem',
                // }}
              >
                <Box
                  className="customerWordsBox"
                  display="flex"
                  justifyContent="left"
                  marginRight="10px"
                >
                  <Paper
                    className="customerWords"
                    sx={{
                      borderRadius: '31.25rem',
                      height: 'auto',
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'left',
                      boxShadow: '0',
                      background: 'transparent',
                    }}
                  >
                    <Typography className="testimonialTitle">
                      Customer Words
                    </Typography>
                  </Paper>
                </Box>

                {/* Repeatable Review Boxes */}

                <Slider {...settings}>
                  {reviewData[0].data.map((data: any, index: number) => (
                    <Grid
                      className="testimonialGrid"
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '30px',
                        paddingBottom: '50px',
                      }}
                    >
                      <Paper
                        className="testimonialArea"
                        elevation={2}
                        sx={{
                          borderRadius: '10px',
                          backgroundColor: '#f6f6f6',
                          width: '100%',
                          padding: '20px',
                          maxWidth: '100%', // Ensure a max width for the cards
                          height: { xl: 'auto', md: '20.625rem' },
                          display: 'flex',
                          justifyContent: 'left',
                          alignItems: 'center',
                        }}
                      >
                        <Box width="100%">
                          <Typography
                            className="testimonialDesc"
                            sx={{
                              textAlign: 'left',
                              fontSize: {                                
                                xl: '16px', // extra large screen
                              },
                              fontWeight: '400',
                              minHeight: {
                                xl: '145px',
                                lg: '170px',
                                md: '80px',
                                sm: '60px',
                                xs: '40px',
                              },
                              color: '#636363',
                              lineHeight: '28px',
                            }}
                            // className='customerReviewText'
                          >
                            {data.description}
                            <br />
                            <br />
                          </Typography>
                          <div className="testimonialBottom">
                            <Rating
                              sx={{
                                marginLeft: '0',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flexStart',
                                marginBottom: '30px',
                              }}
                              name="simple-controlled"
                              value={data?.rating ? parseInt(data?.rating) : 0}
                              readOnly={true}
                            />
                            <hr
                              style={{
                                width: '100%',
                                border: '1px solid #DCDCDC',
                              }}
                            ></hr>
                            <Box
                              className="userDetails"
                              sx={{
                                marginTop: {
                                  xs: '20px',
                                  sm: '10px',
                                  md: '20px',
                                },
                                fontSize: '1.125rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'left',
                              }}
                            >
                              <Box
                                className="customeerCircle"
                                sx={{
                                  width: '45px',
                                  height: '45px',
                                  borderRadius: '50%',
                                  backgroundColor: '#4E297B', // Change to desired background color
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginRight: '10px',
                                }}
                              >
                                <Typography
                                  className="customerInitials"
                                  sx={{
                                    color: '#fff',
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {getInitials(data.name)}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="left"
                                flexDirection="column"
                                alignItems="flex-start"
                                className="customerWrap"
                              >
                                <Typography className="customerName">
                                  {' '}
                                  {data.name}
                                </Typography>
                                <Typography className="customerLocation">
                                  {data.address}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Slider>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
