import React from 'react';
import { Box, Button } from '@mui/material';
import './PlanTypeButton.css';

interface PlanTypeButtonProps {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const PlanTypeButton: React.FC<PlanTypeButtonProps> = ({
  selectedTab,
  setSelectedTab,
}) => {

  const buttons = [{
    label: "PROFESSIONALS",
    key: "2"
  },
  {
    label: "SALONS",
    key: "1"
  },
  {
    label: "SPA",
    key: "3"
  },
];

  return (
      <Box mb={4} className="plan_type"
      >
        {buttons.map(btn => (
          <Button
          key={parseInt(btn.key)}
            className={`plan_button ${selectedTab === btn.key ? 'selected' : ''}`}
            onClick={() => setSelectedTab(btn.key)}
            >
            {btn.label}
          </Button>
        ))}
        </Box>
  );
};

export default PlanTypeButton;
