import React, { useState } from 'react';
import { Box, Typography, Grid, CardMedia, IconButton } from '@mui/material';
import './videos.css'; // Import your CSS file
import { AcadamicData } from '../../../../src/api/types/shop';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface academicProps {
  data: AcadamicData[];
}

// Function to extract the YouTube video ID
const getYouTubeVideoId = (urlOrId: string): string => {
  const urlRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = urlOrId.match(urlRegex);
  return match ? match[1] : urlOrId; // If matched, return ID; otherwise, assume it's already an ID
};

const AcademyVideos: React.FC<academicProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);

  const openModal = (videoId: string) => {
    setCurrentVideo(videoId);
    setIsOpen(true);
  };

  const closeModal = () => {
    setCurrentVideo(null);
    setIsOpen(false);
  };

  return (
    <Box className="academy-videos-container">
      <Box className="divider" />
      <Typography variant="h4" className="title">
        Academy Videos
      </Typography>

      <Grid container spacing={2} className="video-grid">
        {data.map((object: AcadamicData, index: number) => {
          const videoId = getYouTubeVideoId(object.media_name);
          return (
            <Grid item xs={12} md={3} key={index} className="video-item">
              <Box className="video-wrapper" onClick={() => openModal(videoId)}>
                <CardMedia
                  component="iframe"
                  width={262}
                  height={254}
                  src={`https://www.youtube.com/embed/${videoId}?controls=0`} // Disable controls for the thumbnail
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <Box className="video-overlay" />
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {currentVideo && (
        <BootstrapDialog
          onClose={closeModal}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
          className="appt"
        >
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 1000, // Ensure it's above other elements if needed
            }}
          >
            <IconButton
              onClick={closeModal}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: '#fff',
                width: '3rem',
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            <iframe
              style={{ border: 'none', width: '100%', height: '100%' }}
              src={`https://www.youtube.com/embed/${currentVideo}?autoplay=1`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
            ></iframe>
          </Box>
        </BootstrapDialog>
      )}
    </Box>
  );
};

export default AcademyVideos;
