import React from 'react';
import { Box, Typography, Button, Grid,Paper } from '@mui/material';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import './AppDownloadSection.css';

const AppDownloadSection: React.FC = () => {
  return (
    <Grid container className='appDownload'>
        <Grid item xs={12} lg={6} xl={6}>
          <Box className="box" >
            <Box className="group" >
              <Paper />
              {/* Group Wrapper for the second image */}
              <Typography className="download-the" >
                Download the <span>Glam9 app</span>                
              </Typography>
              <Typography className="manage-your-schedule" >
                Manage your schedule effortlessly, and indulge in self-care that
                fits your lifestyle
              </Typography>
              <Box className="group-wrapper">
                <p>Available on </p>
              </Box>
              <div className='storeImage'>
              <img className="img" alt="Group" src={icons.apple} />
              <img className="group-2" alt="Group" src={icons.google} />
              </div>
            </Box>
          </Box>  
        </Grid>      
        <Grid item xs={12} lg={6} xl={6}>
              {/* Group Wrapper for the first image */}
          <div className='imageWrap'>
          <div className='ios'>
            <img src={images.glam_iOS} ></img>
            </div>
            <div className='android'>
              <img src={images.glam_android} ></img>
            </div>
            
          </div>
        </Grid>
      </Grid>
  );
};
export default AppDownloadSection;