import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { icons } from '../../../Assets/icons';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import './Spinner.css';


const Spinner: React.FC = () => {
  return (
    <div className="loaderSpinner">
    <span className="loader"></span>
</div>
  );
};

export default Spinner;