import * as React from 'react';
import { Box, Typography, Grid, Button, Paper } from '@mui/material';
import './BeautySpot.css';
import { useNavigate } from 'react-router-dom';

import { icons } from '../../../src/Assets/icons';
import { images } from '../../../src/Assets/images';

interface VendorAddress {
  [key: string]: string | undefined; // Dynamic keys with string values
}

interface Address {
  [key: string]: string | undefined;
}

interface BeautySpotProp {
  photo: string;
  name: string;
  vendor_detail_id: string;
  rating: string;
  gender: number;
  address: Address;
}

const BeautySpot: React.FC<BeautySpotProp> = ({
  photo,
  name,
  vendor_detail_id,
  rating,
  gender,
  address,
}) => {
  const navigate = useNavigate();
  function gotoShop() {
    try {
      localStorage.setItem('shopID', vendor_detail_id.toString());
      navigate('/shop');
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  }

  const hasVendorAddress = address && Object.keys(address).length > 0;

  // Function to construct the address
  const constructAddress = (address?: VendorAddress): string => {
    if (!address) return ''; // Return empty if no address

    const parts: string[] = [];

    // Iterate over the keys of the address object
    for (const key in address) {
      if (key !== 'lattitude' && key !== 'longitude' && address[key]) {
        // Check if the value is not empty
        parts.push(address[key]!); // Use non-null assertion since we checked for undefined
      }
    }

    return parts.join(', '); // Join the parts with a comma
  };

  let locationAddress = '';

  if (hasVendorAddress) {
    locationAddress = constructAddress(address);
  }

  return (
    <div onClick={gotoShop} className="beautyspotContainer">
      <Box className="imageSection">
        <img loading="lazy" src={photo} className="mainImage" alt={name} />
      </Box>
      <div className="contentSection">
        <Box className="infoSection">
          <Box className="titleContent">
            <Typography variant="h5" className="salonName">
              {name}
            </Typography>
            <Typography variant="body2" className="salonType">
              {gender == 1
                ? 'Male Salon'
                : gender == 2
                  ? 'Female Salon'
                  : 'Unisex Salon'}
            </Typography>
          </Box>
          <Box className="ratingContainer">
            <Typography variant="body1" className="ratingText">
              {rating}
            </Typography>
            <img
              loading="lazy"
              src={icons.rating}
              alt="Rating star icon"
              className="rating-star-icon"
            />
          </Box>
          </Box>  
          <Box className="locationContent">
            {locationAddress && (
              <Box className="locationContainer">
                <img
                  loading="lazy"
                  src={images.location}
                  className="location-icon"
                  alt="Location pin icon"
                />
                <Typography component="p" className="locationAddress">
                  {locationAddress}
                </Typography>
              </Box>
            )}
          </Box>
          
      </div>
    </div>
  );
};
export default BeautySpot;
