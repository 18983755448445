import React from 'react';
import { Box, Grid, Typography,Button } from '@mui/material';
import { icons } from '../../../Assets/icons';


const weekNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const convertToAmPm = (time: any) => {
  const [hour, minute] = time.split(':').map(Number);
  const ampm = hour >= 12 ? 'pm' : 'am';
  const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
  return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
};

interface OpeningTimesProps {
  OpeningTimesProp: any; // Replace `any` with a specific type, e.g., `string`, `object`, etc.
}


const OpeningTimes: React.FC<OpeningTimesProps> = ({OpeningTimesProp}) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" className="heading membership">
        Opening Times
      </Typography>
      <Grid container className="opening-times">
                  <Grid container spacing={2}>
                    {/* Weekday and status */}
                    {weekNames.map((day, index) => {
                      // Find matching day in the schedule data
                      const matchingDay = OpeningTimesProp?.schedule?.data?.find(
                        (row: any) => row.day_name === day
                      );
                      // Check if the day is closed
                      const isClosed = !matchingDay || matchingDay.is_closed === '1';
                      return (
                        <Grid item xs={12} key={index} className="day">
                          <Grid item xs={6} className="days">
                            <Box key={index} className="day">
                              <Box display="flex" alignItems="center">
                                {/* Status Dot */}
                                <Box
                                  className="status-dot"
                                  sx={{
                                    width: 15,
                                    height: 15,
                                    borderRadius: '50%',
                                    backgroundColor: isClosed ? '#FB0808' : '#3EDA30',
                                    marginRight: 1,
                                  }}
                                />
                                {/* Weekday Name */}
      
      
      
                                <Typography
                                  sx={{
                                    color: isClosed ? '#FB0808' : 'inherit',
                                  }}
                                >
                                  {day}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          {/* Operating Hours or Closed Message */}
                          <Grid item xs={6} className="days">
                            <Box key={index} className="day">
                              <Box className="" />
                              {isClosed ? (
      
                                <Typography sx={{ color: 'red', marginLeft: 2 }}>Closed</Typography>
                              ) : (
                                <Typography sx={{ marginLeft: 2 }}>
                                  {convertToAmPm(matchingDay.start_time)} - {convertToAmPm(matchingDay.end_time)}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
      
      
      
      
                </Grid>
    </Box>
  );
};
export default OpeningTimes;