import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
  Grid,
  Rating,
} from '@mui/material';
import './Sidebar.css';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import toast from 'react-hot-toast';
import PhoneInput from '../../../Components/login/phoneInput/phoneInput';
import OpeningTimes from '../OpeningTimes/OpeningTimes';
import Dialog from '@mui/material/Dialog';
import AppointmentBooking from './AppointmentBooking';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const Sidebar = (props: any) => {
  const [open, setOpen] = React.useState(false);

  let [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  let [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  let [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  function handleSubmit() {
    let isValid = true;
    setNameError('');
    setEmailError('');
    setPhoneError('');
    name = name.trim();
    email = email.trim();
    phone = phone.trim();
    if (name === '') {
      setNameError('Please enter your name');
      isValid = false;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid Email! Please enter valid Email');
      isValid = false;
    }
    if (email === '') {
      setEmailError('Please enter your Email');
      isValid = false;
    }
    if (phone.length !== 10) {
      setPhoneError('phone number should be 10 digits');
      isValid = false;
    }
    if (isNaN(parseInt(phone))) {
      setPhoneError('Phone Number should be numeric');
      isValid = false;
    }
    if (phone === '') {
      setPhoneError('Please enter your Phone Number');
      isValid = false;
    }

    if (isValid === true) {
      toast.success('Thanks for your information. we will contact you soon');
    }
  }

  const handleClickOpen = () => {
    localStorage.removeItem('shop_category_id');
    localStorage.removeItem('shop_service_id');
    localStorage.removeItem('bookFromSidebar');

    let vendorsServiceData = props?.servicesDataOfVendor[0] || [];

    let catoID = vendorsServiceData?.category_id || '';

    localStorage.setItem('shop_category_id', catoID);
    localStorage.setItem('bookFromSidebar', 'true');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <aside className="sidebar">
      <Box className="container">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="appt"
        >
          <AppointmentBooking handleClose={handleClose} />
        </BootstrapDialog>
        <Box className="profile-card">
          <Box className="header" gap={0}>
            {/* <Avatar className="avatar">C<br />&<br />V</Avatar> */}
            {props?.vendorData?.business_logo !== '' ? (
              <img
                className="businessListImage"
                src={props?.vendorData?.business_logo}
                alt="Crown"
              />
            ) : (
              <Box className="initialls">
                <Typography
                  variant="h4" // Adjust font size as needed
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1.35rem',
                  }}
                >
                  {props.convertNameToImage(
                    props?.vendorData?.vendor_detail.data.business_name,
                  )}
                </Typography>
              </Box>
            )}
            {props?.vendorData?.vendor_detail?.data?.salon_type == '1' && (
              <img
                className="businessListImage crown"
                src={images.crown}
                alt="Crown"
              />
            )}
            <Box className="details">
              <Typography variant="h3" className="shop-name">
                {props.vendorData?.vendor_detail.data.business_name}
              </Typography>
              <Grid container item xs={12}>
                <Grid item xs={12} className="sidebarRating">
                  <Rating
                    value={
                      props.vendorData?.vendor_detail?.data?.rating_value
                        ? parseInt(
                            props.vendorData?.vendor_detail?.data?.rating_value,
                          )
                        : 0
                    }
                    precision={0.5}
                    readOnly
                    sx={{ marginRight: 2, fontSize: '35px', float: 'left' }}
                  />

                  <Typography variant="h2" className="shopTitleRating">
                    {props.vendorData?.vendor_detail.data.rating}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {props.vendorData?.vendor_detail.data.opening_status && (
            <Box className="status">
              <img
                loading="lazy"
                src={icons.time}
                alt=""
                className="status-icon"
              />
              <Typography
                variant="h4"
                className={
                  props.vendorData?.vendor_detail.data.opening_status === 'Open'
                    ? 'shopOpened'
                    : 'shopClosed'
                }
                sx={{ marginRight: 2 }}
              >
                {props.vendorData?.vendor_detail.data.opening_status}
              </Typography>
              <Typography
                variant="h2"
                className="shopOpensAt"
                sx={{ marginRight: 2 }}
              >
                - {props.vendorData?.vendor_detail.data.opening_time}
              </Typography>
            </Box>
          )}
          {props.vendorData?.vendor_detail.data.address && (
            <Box className="address">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  props.vendorData?.vendor_detail.data.address?.replace(
                    /,$/,
                    '',
                  ) || '',
                )}`}
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={icons.location}
                  alt=""
                  className="address-icon"
                />
              </a>
              <Typography className="address-text">
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    props.vendorData?.vendor_detail.data.address?.replace(
                      /,$/,
                      '',
                    ) || '',
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="google-maps-link"
                >
                  {props.vendorData?.vendor_detail.data.address?.replace(
                    /,$/,
                    '',
                  )}
                </a>
              </Typography>
            </Box>
          )}
          {props.isServicesAvail &&
            props.vendorData?.vendor_detail?.data?.is_online_book === '1' &&
            Number(
              props.vendorData?.vendor_detail?.data?.subscription_plan_id,
            ) > 1 && (
              <Button
                variant="contained"
                onClick={handleClickOpen}
                className="book-button"
              >
                Book Now
              </Button>
            )}
          <Divider className="divider" />
          <br></br>
          <OpeningTimes OpeningTimesProp={props.vendorData} />
          {/* opening times */}
        </Box>

        {/* <form className="offers-form">
          <Typography
            variant="h3"
            sx={{ textAlign: 'left' }}
            className="form-title"
          >
            Get Offers
          </Typography>
          <Typography
            className="form-subtitle"
            sx={{ textAlign: 'left', marginBottom: 4, fontSize: '18px' }}
          >
            For get latest update
          </Typography>
          <TextField
            id="fullName"
            label="Full Name"
            variant="outlined"
            className="text-field first"
            error={!!nameError} // Boolean for error prop
            helperText={nameError}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            id="emailAddress"
            label="Email Address"
            type="email"
            variant="outlined"
            className="text-field"
            error={!!emailError} // Boolean for error prop
            helperText={emailError}
            onChange={e => setEmail(e.target.value)}
          />
          <PhoneInput
            email={phone}
            setEmail={setPhone}
            emailerror={phoneError}
          />
          {/*  <Grid container spacing={2} className="phone-container">
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Country Code</InputLabel>
                <Select defaultValue="+91">
                  <MenuItem value="+91">+91</MenuItem>
                  
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="phoneNumber"
                label="Your Phone Number"
                variant="outlined"
                className="text-field"
              />
            </Grid>
          </Grid> */}
        {/*  <Button
            type="button"
            onClick={handleSubmit}
            variant="contained"
            className="submit-button"
          >
            Book Now
          </Button>
        </form> */}
      </Box>
    </aside>
  );
};

export default Sidebar;
