import React from 'react';
import { Box, Typography } from '@mui/material';

interface AppointmentOptionProps {
  title: string;
  description: string;
  onClick: () => void;
}

const AppointmentOption: React.FC<AppointmentOptionProps> = ({
  title,
  description,
  onClick,
}) => (
  <Box
    onClick={onClick}
    className="appointmentOptionContainer"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      px: { xs: 2, md: 4 },
      py: 3,
      mt: 2.5,
      maxWidth: '100%',
      borderRadius: 2,
      border: '1px solid #000',
      borderColor: 'black',
      color: 'black',
      width: { xs: '100%', md: 450 },
    }}
  >
    <Typography variant="h5" align="center" fontWeight="bold">
      {title}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1.5 }}>
      {description}
    </Typography>
  </Box>
);

export default AppointmentOption;
