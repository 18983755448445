import React from 'react';
import { Button, Box } from '@mui/material';
import './TimeSelector.css';

type TimeSelectorProps = {
  timeSlots: any;
  setSelectedTime: Function;
  selectedTime: string;
  selectedDate: string;
  checkAppointmentAvaibilityAPICallFunc: Function;
};

const TimeSelector: React.FC<TimeSelectorProps> = ({
  timeSlots,
  setSelectedTime,
  selectedTime,
  selectedDate,
  checkAppointmentAvaibilityAPICallFunc,
}) => {
  return (
    <Box className="time-selector">
      {timeSlots.map((data: any, index: number) => (
        // <Button
        //   onClick={() => {
        //     setSelectedTime(data.time);
        //     checkAppointmentAvaibilityAPICallFunc(selectedDate, data.time);
        //   }}
        //   key={index}
        //   disabled={data.slot_type === '4'} // Disable button when slot_type is "4"
        //   variant="outlined"
        //   className={`time-button ${selectedTime === data.time ? 'selected' : ''}`}
        // >
        //   {data.time}
        // </Button>

        <>
          {data.slot_type === '0' && (
            <Button
              onClick={() => {
                setSelectedTime(data.time);
                checkAppointmentAvaibilityAPICallFunc(selectedDate, data.time);
              }}
              key={index}
              variant="outlined"
              className={`${selectedTime === data.time ? 'time-button-available-selected' : 'time-button-available'}`}
            >
              {data.time}
            </Button>
          )}

          {data.slot_type === '1' && (
            <Button
              onClick={() => {
                setSelectedTime(data.time);
              }}
              key={index}
              variant="outlined"
              className={`time-button-break`}
            >
              <span>{data.time}</span>
              <span className="time-slot-type">Break</span>
            </Button>
          )}

          {data.slot_type === '2' && (
            <Button
              onClick={() => {
                setSelectedTime(data.time);
                checkAppointmentAvaibilityAPICallFunc(selectedDate, data.time);
              }}
              key={index}
              variant="outlined"
              className={`time-button-premium`}
            >
              <span>{data.time}</span>
              <span className="time-slot-type">Premium</span>
            </Button>
          )}

          {data.slot_type === '3' && (
            <Button
              onClick={() => {
                setSelectedTime(data.time);
              }}
              key={index}
              variant="outlined"
              className={`time-button-block`}
            >
              <span>{data.time}</span>
              <span className="time-slot-type">Blocked</span>
            </Button>
          )}

          {data.slot_type === '4' && (
            <Button
              onClick={() => {
                setSelectedTime(data.time);
              }}
              key={index}
              variant="outlined"
              className={`time-button-booked`}
            >
              <span>{data.time}</span>
              <span className="time-slot-type">Booked</span>
            </Button>
          )}
        </>
      ))}
    </Box>
  );
};

export default TimeSelector;

// {data?.transactionStatus === "0" && (
//   <div className="btn btn-outline-warning badge-light-warning pe-none">
//     <span className="fs-14">Pending</span>
//   </div>
// )}
// {data?.transactionStatus === "1" && (
//   <div className="btn btn-outline-primary badge-light-badge pe-none">
//     <span className="fs-14">Success</span>
//   </div>
// )}
// {data?.transactionStatus === "2" && (
//   <div className="btn btn-outline-danger badge-light-danger pe-none">
//     <span className="fs-14">Failed</span>
//   </div>
// )}
// {data?.transactionStatus === "3" && (
//   <div className="btn btn-outline-info badge-light-info pe-none">
//     <span className="fs-14">Refund</span>
//   </div>
// )}
