import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import DateSelector from './DateSelector/DateSelector';
import TimeSelector from './TimeSelector/TimeSelector';
import './Time.css'; // Import the separate CSS file
import {
  appointmentAPIResponse,
  appointmentObject,
  appointmentSlotRequestInterface,
} from '../../api/types/appointment_slots';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { appointmentDetailsObject } from '../../api/types/appointment_details';
import { ShopAPI } from '../../hooks/shop';
import { useBreadcrumbs } from '../../contexts/BreadcrumbContext';

interface timeProps {
  appointmentData: appointmentObject[] | null;
  checkAppointmentAvaibilityAPICallFunc: Function;
  bookPage: any;
  getAppoinmentSlotsAPICallFunc: Function;
  vendorID: number;
}
const Time: React.FC<timeProps> = ({
  appointmentData,
  checkAppointmentAvaibilityAPICallFunc,
  bookPage,
  getAppoinmentSlotsAPICallFunc,
  vendorID,
}) => {
  const { getAppoinmentSlotsAPICall } = ShopAPI();

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [timeSlots, setTimeSlots] = useState<any>([]);
  const [open, setOpen] = useState(false);

  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [firstDate, setFirstDate] = useState<Dayjs | null>(null);
  const [lastDate, setLastDate] = useState<Dayjs | null>(null);

  // const [vendorIDForSlots, setVendorIDForSlots] = useState<number>(() => {
  //   const shopID = JSON.parse(localStorage.getItem('shopID') || '0');
  //   return parseInt(shopID);
  // });
  // const shopID = localStorage.getItem('shopID');

  // useEffect(() => {
  //   console.log('Came HERE');

  //   if ( appointmentDataappointmentData?.length <= 0) {
  //     checkAppointmentAvaibilityAPICallFunc(selectedDate, selectedTime);
  //   }
  // }, [selectedDate, selectedTime, checkAppointmentAvaibilityAPICallFunc]);

  // const [appointmentData, setAppointmentData] = useState<
  //   appointmentDetailsObject[] | null
  // >([]);

  // useEffect(() => {
  //   const getAppoinmentSlotsAPICallFunc = async () => {
  //     const req: appointmentSlotRequestInterface = {
  //       vendor_detail_id: vendorIDForSlots,
  //     };
  //     const result: appointmentAPIResponse =
  //       await getAppoinmentSlotsAPICall(req);
  //     if (result === null) {
  //       // toast.error('Failed to fetch vendor services data.');
  //       return; // Exit the function early if the result is null
  //     }
  //     if (result?.status_code === 200) {
  //       setAppointmentData(result.data);
  //     } else {
  //       // toast.error(result?.message);
  //     }
  //   };
  //   getAppoinmentSlotsAPICallFunc();
  // }, []);

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (!appointmentData) return;

    if (appointmentData && appointmentData.length > 0) {
      const availableData = appointmentData
        .filter(item => item.is_available === '1')
        .map(item => item.date);

      if (availableData.length > 0) {
        setFirstDate(dayjs(availableData[0]));
        setLastDate(dayjs(availableData[availableData.length - 1]));
      }

      setSelectedDate(appointmentData[0].date);
      setTimeSlots(appointmentData[0].slots);
      setAvailableDates(availableData || []);
    }
  }, [appointmentData]);

  useEffect(() => {
    setBreadcrumbs(prevBreadcrumbes =>
      prevBreadcrumbes.map(breadcrumb =>
        breadcrumb.id === bookPage
          ? { ...breadcrumb, isVisited: true }
          : breadcrumb,
      ),
    );
  }, []);

  const shouldDisableDate = (date: Dayjs) => {
    if (!firstDate || !lastDate) return false; // If range is not set, allow all dates

    if (date.isBefore(firstDate)) return true; // Disable past dates before the first available date

    if (date.isAfter(lastDate)) return false; // Allow future dates beyond last available date

    return !availableDates.includes(date.format('YYYY-MM-DD')); // Disable missing dates within range
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      const isUnavailable = !availableDates.includes(date.format('YYYY-MM-DD'));

      console.log('isUnavailable', isUnavailable);
      if (isUnavailable) {
        const slotDate = date.format('DD') || new Date().getDate();
        const slotMonth = date.format('MM') || new Date().getMonth() + 1;
        const slotYear = date.format('YYYY') || new Date().getFullYear();
        getAppoinmentSlotsAPICallFunc(vendorID, slotMonth, slotYear);
      }

      setSelectedDate(formattedDate);
      setOpen(false);
    }
  };

  const handleContainerClick = () => {
    setOpen(true);
  };

  return (
    <Grid item xs={12} md={9} className="team-selection">
      <Box
        className="appointment-booking"
        sx={{ pb: { xs: 3, md: 12 }, backgroundColor: 'white' }}
      >
        <Container className="bookingTime" maxWidth="lg">
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography variant="h4" className="time-selection-title">
                      Select Time
                    </Typography>
                    <div onClick={handleContainerClick}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            value={selectedDate ? dayjs(selectedDate) : null}
                            open={open}
                            disablePast
                            onChange={handleDateChange}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            shouldDisableDate={shouldDisableDate}
                            className="custom-date-picker"
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                fullWidth: true,
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <DateSelector
                    setSelectedDate={setSelectedDate}
                    setTimeSlots={setTimeSlots}
                    setSelectedTime={setSelectedTime}
                    appointmentData={appointmentData}
                    selectedDate={selectedDate} // Pass selectedDate from parent
                  />
                  {timeSlots && timeSlots.length > 0 ? (
                    <TimeSelector
                      setSelectedTime={setSelectedTime}
                      timeSlots={timeSlots}
                      selectedTime={selectedTime}
                      selectedDate={selectedDate}
                      checkAppointmentAvaibilityAPICallFunc={
                        checkAppointmentAvaibilityAPICallFunc
                      }
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Grid>
  );
};

export default Time;
