// src/api/userApi.ts

import { apiClient } from './apiClient';
import { ApiResponseFromAPI } from './types/common';
import { getVendorListingReqInterface } from './types/dashboard';

// Get Dashboard Data
export const getDashboardData = (lat : number,long : number) => {
  return apiClient<ApiResponseFromAPI>(
    `/customer-dashboard?lattitude=${lat}&longitude=${long}`,
    { method: 'GET' },
  );
};

// Get Banner Images
export const getBannerImages = () => {
  return apiClient<any>(`/get-banners`, {
    method: 'GET',
  });
};

// Get Vendor Listing
export const getVendorListing = (data: getVendorListingReqInterface) => {
  return apiClient<ApiResponseFromAPI>(`/get-vendor-listing`, {
    method: 'POST',
    body: data,
  });
};

// Get Vendor Listing
export const getVendorOffers = () => {
  return apiClient<ApiResponseFromAPI>(`/get-offers`, {
    method: 'GET',
  });
};
