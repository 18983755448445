import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import PlanTypeButton from './PlanTypeButtons/PlanTypeButton';
import PricingCards from './PricingCards';
import './pricing.css';
import FAQ from './FAQ/Faq';
import faqData from './faq.json';
import BoxComponent from '../BoxComponent/BoxComponent';
import HadshakeOutLinedIcon from '../../Assets/icons/HadshakeOutLinedIcon.svg';
import SupportIcon from '../../Assets/icons/SupportIcon.svg';
import { useNavigate } from 'react-router-dom';
import { CustomerAPI } from '../../../src/hooks/customer';
import toast from 'react-hot-toast';

interface SubscriptionPlan {
  id: string;
  plan_name: string;
  plan_title: string;
  description: string;
  is_popular_plan: string;
  amount: string;
  duration: string;
  duration_title: string;
}

interface MyObject {
  id: string;
  amount: string;
  description: string;
  duration: string;
  duration_title: string;
  is_popular_plan: string;
  plan_name: string;
  plan_title: string;
}

const Pricing: React.FC = () => {
  const { getSubscriptionPlansAPICall } = CustomerAPI();

  const [selectedTab, setSelectedTab] = useState<string | number>('2');

  const [subscriptionPlans, setsubscriptionPlans] = useState<MyObject[]>([]);

  const [isYearly, setIsYearly] = useState<boolean>(true);

  // const currentData = pricingData[selectedTab as keyof typeof pricingData];
  // const currentPrices = currentData[1 ? 'yearly' : 'monthly'];
  // const description = currentData.description;
  // const content = currentData.content;
  const navigate = useNavigate();
  const handleClick = () => {
    window.location.href = 'mailto:info@glam9.com';
  };
  const ContactNow = () => {
    navigate('/pricing');
  };

  const [subscriptionPlansData, setSubscriptionPlansData] = useState<
    SubscriptionPlan[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getSubscriptionPlansFunc = async () => {
      const result: any = await getSubscriptionPlansAPICall(selectedTab);

      if (result === null) {
        toast.error('Something went wrong');
      } else if (result?.status_code === 200) {
        //set State here
        setSubscriptionPlansData(result.data);
      } else if (result?.status_code === 401) {
        toast.error('Unauthorized');
      } else if (result?.status_code === 500) {
        toast.error('Something went wrong');
      } else {
        toast.error(result?.message);
      }
    };
    getSubscriptionPlansFunc();
  }, [selectedTab]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box className="subscriptionPage">
      {/* Header */}
      <Box>
        <Typography
          sx={{
            fontSize: { xs: '25px', sm: '40px', lg: '60px' },
          }}
          className="subscriptionHeader"
        >
          Find the right plan for
          <br />
          <span
            className="subscriptionHeader_subtitle"
            style={{ fontWeight: '700' }}
          >
            {' '}
            your needs
          </span>
        </Typography>
      </Box>

      <Grid className="subscriptionPlan" container spacing={2} py={5}>
        <Grid item xs={12} md={6} className="subscriptionTitle">
          <Typography
            className="subscriptionPlan_title"
            variant="h4"
            gutterBottom
          >
            Subscription{' '}
            <span
              className="subscriptionPlan_span_title"
              style={{ color: '#4E297B' }}
            >
              Plans
            </span>
          </Typography>

          <Typography
            className="titleText"
            sx={{
              color: '#7a7a7a',
              fontSize: { xs: '14px', lg: '18px' },
            }}
          >
            Glow All Year – Unlock Unlimited Pampering with Our
            <span> Exclusive Subscription Plans!</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className="subscriptionTabs">
          <PlanTypeButton
            selectedTab={String(selectedTab)}
            setSelectedTab={setSelectedTab}
          />
        </Grid>
      </Grid>
      <Grid
        style={{ maxWidth: '100%' }}
        item
        spacing={2}
        xs={12}
        md={6}
        lg={6}
        xl={12}
        className="plan_container"
      >
        {subscriptionPlansData.length > 0 &&
          subscriptionPlansData.map(plan => {
            return <PricingCards plan={plan} key={plan.id} />;
          })}
      </Grid>

      <FAQ data={faqData} />

      <Box className="subscriptionBlock">
        <Grid className="contact-box">
          <BoxComponent
            icon={<img src={SupportIcon} alt="Support Icon" />}
            title="Need more help?"
            description="Unlock Unlimited Pampering with Our Exclusive Subscription Plans!"
            buttonText="info@glam9.com"
            onButtonClick={handleClick}
          />
          <BoxComponent
            icon={<img src={SupportIcon} alt="Support Icon" />}
            title="Looking for support?"
            description="Visit our support portal for product or account help."
            buttonText="Contact Now"
            onButtonClick={ContactNow}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default Pricing;
