import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import './ServiceNavigation.css';
import { icons } from '../../../Assets/icons';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbContext';
interface navigationProps {
  bookPage: number;
  setBookPage: Function;
  navigationFunc: Function;
  setTeamSelectionType: Function;
  setIsSlotAvailbel: Function;
  vendorDataDetail: any;
}
const ServiceNavigation: React.FC<navigationProps> = ({
  bookPage,
  setBookPage,
  navigationFunc,
  setTeamSelectionType,
  setIsSlotAvailbel,
  vendorDataDetail,
}) => {
  const { breadcrumbs, setBreadcrumbs, visitedSteps } = useBreadcrumbs();

  // useEffect(() => {
  //   setBreadcrumbs(prevBreadcrumbes =>
  //     prevBreadcrumbes.map(breadcrumb =>
  //       breadcrumb.id === bookPage
  //         ? { ...breadcrumb, isVisited: true }
  //         : breadcrumb,
  //     ),
  //   );
  // }, [])

  const goToPageFunc = (bookPage: number) => {
    setBookPage((prevPage: number) => {
      let nextPageValue = prevPage - 1;

      // Handle specific cases where pages might be skipped
      if (nextPageValue === 2 && vendorDataDetail?.show_team_member !== '1') {
        nextPageValue--; // Skip Team Selection if not needed
      }

      if (nextPageValue === 4 && vendorDataDetail?.is_first !== '1') {
        nextPageValue--; // Skip Visit page if not applicable
      }

      // If trying to go before page 1, redirect to /shop
      if (nextPageValue < 1) {
        navigationFunc('/shop');
        return prevPage; // Prevent unnecessary state update
      }

      // Update relevant state when going back
      if (nextPageValue === 2) {
        setTeamSelectionType(1);
      }

      if (nextPageValue === 3) {
        setIsSlotAvailbel(false);
      } else {
        setIsSlotAvailbel(true);
      }

      return nextPageValue;
    });
  };

  // const goToPageFunc = (bookPage: number) => {
  //   if (bookPage <= 1) {
  //     navigationFunc('/shop');
  //   } else {
  //     let nextPageValue = bookPage - 1;
  //     setBookPage(nextPageValue);
  //     setIsSlotAvailbel(true);
  //     if (nextPageValue === 2) {
  //       setTeamSelectionType(1);
  //     }
  //     if (nextPageValue === 3) {
  //       setIsSlotAvailbel(false);
  //     }
  //   }
  // };

  return (
    <>
      <Box className="service-navigation">
        <Button
          onClick={() => {
            goToPageFunc(bookPage);
          }}
          style={{ lineHeight: '0px' }}
        >
          <img
            loading="lazy"
            src={icons.left}
            alt=""
            className="service-navigation__image"
          />
        </Button>

        <Box
          className="service-navigation__text"
          style={{ flexDirection: 'row', display: 'flex' }}
        >
          {breadcrumbs.map((breadcrumb, index) => (
            <Typography variant="body1" key={breadcrumb.componentKey}>
              <span
                style={{
                  background: 'none',
                  border: 'none',
                  color: breadcrumb.isVisited
                    ? '#231F20'
                    : 'rgba(35, 31, 32, 0.4)',
                  cursor: 'pointer',
                }}
              >
                {'  ' + breadcrumb.label}
                <span className="rightArrow">
                  {index < breadcrumbs.length - 1 && ' > '}
                </span>
              </span>
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};
export default ServiceNavigation;

// <Box className="service-navigation">
//   <Button
//     onClick={() => {
//       goToPageFunc(bookPage);
//     }}
//     style={{ lineHeight: '0px' }}
//   >
//     <img
//       loading="lazy"
//       src={icons.left}
//       alt=""
//       className="service-navigation__image"
//     />
//   </Button>
//   <Box className="service-navigation__text">
//     {bookPage === 1 && (
//       <Typography variant="body1">
//         Services &gt;{' '}
//         <span className="service-navigation__highlight">
//           Select Team &gt; Time &gt; Confirm
//         </span>
//       </Typography>
//     )}
//     {bookPage === 2 && (
//       <Typography variant="body1">
//         Services &gt; Select Team &gt;{' '}
//         <span className="service-navigation__highlight">
//           Time &gt; Confirm
//         </span>
//       </Typography>
//     )}
//     {bookPage === 3 && (
//       <Typography variant="body1">
//         Services &gt; Select Team &gt; Time &gt;{' '}
//         <span className="service-navigation__highlight"> Confirm</span>
//       </Typography>
//     )}
//     {bookPage === 4 && (
//       <Typography variant="body1">
//         Services &gt; Select Team &gt; Time &gt; Confirm
//       </Typography>
//     )}
//     {bookPage === 5 && (
//       <Typography variant="body1">
//         Services &gt; Select Team &gt; Time &gt; Confirm
//       </Typography>
//     )}
//   </Box>
// </Box>

// setBreadcrumbs(prevBreadcrumbes =>
//   prevBreadcrumbes.map(
//     breadcrumb => (
//       console.log('breadcrumb.id , bookPage', breadcrumb.id, bookPage),
//       breadcrumb.id > bookPage
//         ? { ...breadcrumb, isVisited: false }
//         : breadcrumb
//     ),
//   ),
// );
