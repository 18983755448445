import React, { useState } from 'react';
import { Box, Paper, Typography, Grid, Container } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BrandLogo from '../login/brandLogo/brandLogo';
import { Button } from '@mui/material';
import OtpInput from 'react-otp-input';
import toast, { Toaster } from 'react-hot-toast';
import { useAppTranslation } from '../../Utils/i18n';
import AuthService from '../../Services/auth.service';
import { icons } from '../../Assets/icons';
import { images } from '../../../src/Assets/images';
import { useCookies } from 'react-cookie';
import CommonCookie from '../../Components/CommonCookie';
import { useAppDispatch } from '../../redux/hooks';
import { setUserDetails } from '../../redux/features/userSlice';
import Spinner from '../../../src/Common/Loader/Spinner/Spinner';

import './Otp.css';

interface VerifyPhoneProps {
  phoneNumber: string;
}

const getDeviceInfo = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  let deviceType = 'Unknown';
  let deviceOS = 'Unknown';
  let deviceName = 'Unknown';

  // Detect OS
  if (/Windows NT/i.test(userAgent)) deviceOS = 'Windows';
  else if (/Mac OS/i.test(userAgent)) deviceOS = 'macOS';
  else if (/Linux/i.test(userAgent)) deviceOS = 'Linux';
  else if (/Android/i.test(userAgent)) deviceOS = 'Android';
  else if (/iPhone|iPad|iPod/i.test(userAgent)) deviceOS = 'iOS';

  // Detect device type
  if (/iPhone/i.test(userAgent)) {
    deviceType = '1';
  } else if (/iPad/i.test(userAgent)) {
    deviceType = '1';
  } else if (/Android/i.test(userAgent)) {
    deviceType = '2';
  } else if (/Mobile/i.test(userAgent)) {
    deviceType = '2';
  } else if (/Tablet/i.test(userAgent)) {
    deviceType = '2';
  } else {
    deviceType = '3';
  }

  // Device name detection (simplified)
  if (/Windows NT 10.0/i.test(userAgent)) deviceName = 'Windows 10';
  else if (/Windows NT 6.1/i.test(userAgent)) deviceName = 'Windows 7';
  else if (/Macintosh/i.test(userAgent)) deviceName = 'Mac';
  else if (/Android/i.test(userAgent)) deviceName = 'Android Device';
  else if (/iPhone/i.test(userAgent)) deviceName = 'iPhone';
  else if (/iPad/i.test(userAgent)) deviceName = 'iPad';

  return { deviceType, deviceOS, deviceName };
};

const Otp: React.FC<VerifyPhoneProps> = ({ phoneNumber }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const authService = AuthService();
  const [otpError, setOtpError] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState('');
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const pNumber = location.state?.number || 'Default number';
  const phNumber = pNumber.slice(0, 2) + '******' + pNumber.slice(-2);
  const redirectPath = location.state?.from || '/';
  const [loading, setLoading] = useState(false);

  // const prevPageRoute = location.state?.prevPage;

  function checkOtp() {
    setOtpError('');

    if (verificationCode.length < 6) {
      setOtpError('OTP fields should not be blank');
    } else {
      login(verificationCode);
    }
  }

  async function HandleVerificationCodeChange(e: any, fields: any) {
    setVerificationCode(e);
  }

  const sendOtp = async () => {
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', pNumber);
    fd.append('is_web', '1');
    try {
      const response = await authService().sendOtp(fd);

      toast.success(response.data.message);
      //return false;
    } catch (error: any) {}
  };

  const login = async (otp: string) => {
    const { deviceType, deviceOS, deviceName } = getDeviceInfo();
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', location.state?.number || '');
    fd.append('otp', otp || '');
    fd.append('device_type', deviceType || '');
    fd.append('device_os', deviceOS || '');
    fd.append('device_name', deviceName || '');
    console.log(fd);
    try {
      setLoading(true);
      const response = await authService().login(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        localStorage.removeItem('otp_verification');
        localStorage.setItem('customer_auth_token', response.data.token);
        dispatch(setUserDetails(response.data.data));
        localStorage.setItem(
          'customer_details',
          JSON.stringify(response.data.data),
        );
        setCookie(CommonCookie.token, response.data?.data?.token, {
          path: redirectPath,
          secure: true,
          sameSite: 'lax',
        });
        setLoading(false);
        setVerificationCode('');
        navigate(redirectPath, { replace: true });
      } else {
        // console.log('response.data ---->>>', response.data);

        setLoading(false);
        // setVerificationCode('');
        toast.error(response.data.message, {
          duration: 900,
        });
      }
    } catch (error: any) {
      // console.log('error?.data ---->>>', error?.data);

      setLoading(false);
      // setVerificationCode('');
      toast.error(error?.data?.message || 'Oops, Something went wrong.', {
        duration: 900,
      });
    }
  };

  const t = useAppTranslation();
  return (
    <Box className="otp login otp_form">
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} md={6} className="login-section">
          <Paper elevation={3} className="login-form-container">
            <Container className="login-form" maxWidth="sm">
              <Box className="topBar">
                <Link to="/login">
                  <img
                    loading="lazy"
                    src={icons.purpleBack}
                    alt=""
                    className="login-form-logo"
                  />
                </Link>
                <div className="logoMobile">
                  <img src={images.logo} />
                </div>
              </Box>
              <div className="otpContent">
                <center>
                  <Grid
                    container
                    justifyContent="center"
                    className="pwdSection otp"
                  ></Grid>
                  {/* <small id="emailHelp" className="form-text text-danger">
                    {otpError}
                  </small> */}

                  <Typography
                    variant="h4"
                    component="h1"
                    className="login-form-title"
                  >
                    {/* {t('verifyPhone')} */}
                    {'Verify Phone'}
                  </Typography>
                  <Typography variant="body1" className="login-form-subtitle">
                    Code has been sent to +91 {phNumber}
                  </Typography>
                </center>
                <Grid
                  container
                  justifyContent="center"
                  className="pwdSection otp"
                >
                  <Box className="otp-input">
                    <OtpInput
                      value={verificationCode}
                      onChange={async (e: any) => {
                        await HandleVerificationCodeChange(e, '');
                      }}
                      numInputs={6}
                      shouldAutoFocus
                      placeholder="-"
                      renderInput={props => <input {...props} />}
                    />
                  </Box>
                </Grid>
                <small id="emailHelp" className="form-text text-danger">
                  {otpError}
                </small>

                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className="otp"
                >
                  <Typography variant="body2" color="error"></Typography>
                  <Typography variant="h4">
                    {/* {t('didntReceiveCode')}{' '} */}
                    {/* <span onClick={sendOtp}>{t('resend')}</span> */}
                    Didn't Receive the Code{' '}
                    <span onClick={sendOtp}>Resend</span>
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={checkOtp}
                    color="primary"
                    fullWidth
                    sx={{ backgroundColor: '#0178FA' }}
                    className={`otp-form-button ${loading ? 'otpBtnDisable' : ''} `}
                    disabled={loading}
                  >
                    Continue {loading ? <Spinner /> : ''}
                  </Button>
                </Grid>
              </div>
            </Container>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="logo-section">
          <Box className="brand-container">
            <img
              loading="lazy"
              src={icons.loginLogo}
              alt=""
              className="brand-image"
            />
          </Box>
        </Grid>
      </Grid>
      <Toaster />
    </Box>
  );
};

export default Otp;

// async function HandleVerificationCodeChange(e: any, fields: any) {
//   setVerificationCode(e);
//   if (e.length === 6) {
//     login(e);
//   }
// }
