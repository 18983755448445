import React from 'react';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import './Breadcrumb.css';
import { icons } from '../../Assets/icons';
import { useNavigate } from 'react-router-dom';
type BreadcrumbProps = {
  currentPage: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ currentPage }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
    };
  return (
    <nav aria-label="Breadcrumb" className="breadcrumb-container">
      <img
        loading="lazy"
        src={icons.purpleBack}
        alt=""
        onClick={handleBack}
        className="breadcrumb-image"
      />
      <Box className="breadcrumb-content">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography sx={{cursor:'pointer'}} onClick={handleBack}>
            Home
          </Typography>
          <Typography color="textPrimary">{currentPage}</Typography>
        </Breadcrumbs>
      </Box>
    </nav>
  );
};

export default Breadcrumb;
