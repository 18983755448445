import React from 'react';
import { Box, Typography } from '@mui/material';
import './TeamMember.css';
interface TeamMemberProps {
  name: string;
  image: string;
  description?: string;
  isSpecial?: boolean;
}

const TeamMemberType: React.FC<TeamMemberProps> = ({
  name,
  image,
  description,
  isSpecial,
}) => {
  return (
    <Box className={`team-member ${isSpecial ? 'special' : ''}`}>
      <Box
        className={`card ${isSpecial && name === 'Any Professional' ? 'border-blue' : 'border-stone'}`}
      >
        <img
          loading="lazy"
          src={image}
          alt={name}
          className={`image ${isSpecial ? 'special-image' : 'regular-image'}`}
        />
        <Typography
          variant={isSpecial ? 'h5' : 'h4'}
          className={`name ${isSpecial ? 'special-name' : ''}`}
        >
          {name}
        </Typography>
        {description && (
          <Typography variant="body1" className="description">
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TeamMemberType;
