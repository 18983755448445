import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './confirm.css';

interface MyComponentProps {}

const Confirm: React.FC<MyComponentProps> = () => {
  return (
    <main className="confirm">
      <Container className="confirm" maxWidth="xl">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className="content-wrapper"
        >
          <Grid item xs={12} md={7}></Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Confirm;
