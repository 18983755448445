import React from 'react';
import { Container, Box, Grid } from '@mui/material';
import './Review.css';
const Review: React.FC = () => {
  return (
    <Box className="app-container">
      <Container className="review">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className="main-content"
        >
          <Grid item xs={12} md={5} className="progress-review"></Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Review;
