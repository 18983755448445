import React from 'react';
import { Button, Box, Typography, Rating, Grid } from '@mui/material';
import ReviewCard from '../ReviewCard/reviewCard';
import './Review.css'; // Separate CSS file
import { ReviewDetailsData } from '../../../../src/api/types/shop';
import { useNavigate, useLocation } from 'react-router-dom';
type ReviewsProps = {
  salonName: string;
  reviews: ReviewDetailsData[];
  overallRating: string;
  totalReviews: number;
};
const Reviews: React.FC<ReviewsProps> = ({
  salonName,
  reviews,
  overallRating,
  totalReviews,
}) => {

  const navigate = useNavigate();
  const ReviewAll = () => {
    navigate('/reviews', { state: { name: salonName, overallRating, totalReviews } });
  };
  return (
    <section className="reviews-section onlyDesktop" >
      <Box className="reviews-header">
        <Box className="reviews-title">
          <Typography variant="h2">Reviews</Typography>
          <Box>
            <Rating
              value={parseFloat(overallRating)}
              precision={0.5}
              readOnly
              className='shopRating'
              sx={{ marginRight: 2, fontSize: '35px' }}
            />
            <Typography variant="h5" className="overall-rating">
              {overallRating} ({totalReviews})
            </Typography>
          </Box>
        </Box>
        <Button variant="contained" onClick={ReviewAll} className="see-all-button">
          See All
        </Button>
      </Box>
      <Box className="reviews-list" sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2}>
          {reviews.map((review, index) => (
            <Grid
              item
              xs={12} // Full width on extra-small screens
              sm={6} // Two cards per row on small screens
              md={6} // Three cards per row on medium screens
              lg={6} // Four cards per row on large screens
              key={index}
            >
              <ReviewCard data={review} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </section>
  );
};

export default Reviews;
