import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { icons } from '../../Assets/icons';
import './Support.css'; // Assuming you'll add any additional custom styles
import { CommonAPI } from '../../../src/hooks/common';
import { ContactSupportReqInterface } from '../../../src/api/types/common';
import toast from 'react-hot-toast';
import PhoneInput from '../login/phoneInput/phoneInput';
import { Box, InputBase, Typography, CircularProgress,Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Spinner from '../../../src/Common/Loader/Spinner/Spinner';
import { default as CustomPhoneInput } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the default styles


interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  subject: string;
}

const Support: React.FC = () => {

  

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim('Spaces is not allowed for first name')
      .strict(true)
      .required('Please enter first name')
      .test(
        'valid-name',
        'Spaces is not allowed for first name',
        value => !!value?.trim(),
      ),
    lastName: Yup.string()
      .trim('Spaces is not allowed for last name')
      .strict(true)
      .test(
        'valid-name',
        'Spaces is not allowed for last name',
        value => !value || !!value.trim(),
      ),
    email: Yup.string()
      .trim('Spaces is not allowed for email') // Trim spaces
      .strict(true) // Ensure no untrimmed strings pass
      .email('Invalid email address') // Validate email format
      .required('Please enter email') // Ensure the field is filled
      .test(
        'valid-email',
        'Spaces is not allowed for email',
        value => !!value?.trim(), // Ensure email is not just spaces
      ),

    phoneNumber: Yup.string()
      .trim('Spaces is not allowed for phone number')
      .strict(true)
      .matches(/^[\d\s\-().]{10,15}$/, 'Phone number must be valid')
      .test(
        'is-10-digits',
        'Phone number must be 10 digits',
        value => value?.replace(/\D/g, '').length === 10,
      )
      .required('Please enter phone number'),
    message: Yup.string()
      .trim('Spaces is not allowed for message') // Trim spaces
      .strict(true) // Ensure no untrimmed strings pass
      .required('Please enter inquiry message') // Ensure the field is filled
      .test(
        'valid-name',
        'Spaces is not allowed for message',
        value => !!value?.trim(), // Ensure email is not just spaces
      ),
    subject: Yup.string().required('Please select a subject'),
  });

  const { addContactSupportAPICall } = CommonAPI();
  const navigate = useNavigate();

  const [customerId, setCustomerId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState("");

  useEffect(() => {
    // Retrieve data from localStorage and parse it
    const customerDetails = localStorage.getItem('customer_details');

    if (customerDetails) {
      const parsedData = JSON.parse(customerDetails);
      setCustomerId(parsedData.customer_id);
    }
  }, []);

  // const classes = useStyles();
  const [phone, setPhone] = useState('');

  const handleOnChange = (value: any) => {
    setPhone(value); // Update phone state
  };

  // const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default country code

  const handleChange = (value: string, data: any) => {
    setPhone(value); // Full phone number
    setCountryCode(`+${data.dialCode}`); // Extract country code
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
      subject: '1',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log('values -->>>', values);

      setLoading(true);
      try {
        let requestBody: ContactSupportReqInterface = {
          subject_type: values.subject.trim(),
          first_name: values.firstName.trim(),
          last_name: values.lastName.trim(),
          email: values.email.trim(),
          country_code: '91',
          mobile: values.phoneNumber.trim(),
          message: values.message.trim(),
          customer_id: customerId,
        };

        // setTimeout(() => {
        //   setLoading(false);
        // }, 3000);
        // addContactSupportAPICallFunc(requestBody, resetForm);

        const result: any = await addContactSupportAPICall(requestBody);
        // const result: any = [];
        if (result === null) {
          toast.error('Something went wrong');
          return;
        }
        if (result?.status_code === 200) {
          setLoading(false);
          resetForm();
          setTimeout(() => {
            
          }, 5000);
          setTimeout(() => { setShowMessage(''); }, 5000); 
          setShowMessage('Your form submitted successfully');;


          
          /* toast.success('Your form submitted successfully', {
            position: 'bottom-center',
          }); */
        } else {
          toast.error(result?.message);
        }
      } catch (error) {
        alert('Failed to send message. Please try again.');
      } finally {
        setLoading(false);
      }
    },

    // onSubmit: async (values, { resetForm }) => {
    //   console.log('Form values:', values);

    //   // Simulate loading state
    //   setLoading(true);
    //   setTimeout(() => {
    //     console.log('Simulated API response received');
    //     setLoading(false);
    //     resetForm();
    //   }, 3000); // Simulate a 2-second API delay
    // },
  });

  // const addContactSupportAPICallFunc = async (
  //   data: ContactSupportReqInterface,
  //   resetForm: () => void,
  // ) => {
  //   const result: any = await addContactSupportAPICall(data);
  //   // const result: any = [];
  //   if (result === null) {
  //     toast.error('Something went wrong');
  //     return;
  //   }
  //   if (result?.status_code === 200) {
  //     resetForm();
  //     toast.success('Your form submitted successfully', {
  //       position: 'bottom-center',
  //     });
  //   } else {
  //     toast.error(result?.message);
  //   }
  // };

  const subjectSelection = [
    {
      subject: 'Customer Support',
      key: '1',
    },
    {
      subject: 'Vendor Support',
      key: '2',
    },
    {
      subject: 'Sales Support',
      key: '3',
    },
  ];

  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);


  const handleInputChange = (event:any) => {
    setMessage(event.target.value);

    // Dynamically adjust the height
  /*  const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height to auto to calculate new height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    } */
  };

  return (
    <div className="customerSupport">
      <div className="container">
        <div className="contact-text">
          <h1>Contact Us</h1>
          <button>Any question or remarks? Just write us a message!</button>
          <p>Discover. Booking. Growth.</p>
        </div>
        <Grid container className="contact-from">
          <Grid item xl={5} xs={12} md={12} lg={5} className="contactinformation">
            <h2>Contact Information</h2>
            <p>Say something to start a live chat!</p>
            <div className='contactInfo'>
              <div className="phone-cart">
                <img src={icons.contactPhone} />
                <p>
                  <a href="tel:9925999579">+91 99259 99579</a>
                </p>
              </div>
              <div className="email-cart">
              <img src={icons.contactMail} />
                <p>
                  <a href="mailto:inquiry@glam9.com">inquiry@glam9.com</a>
                </p>
              </div>
              <div className="loc-cart">
                <img src={icons.contactLocation} />
                <p>
                205, Iscon Elegance, SG Highway,
Service Rd, Prahlad Nagar,
Ahmedabad, Gujarat 380015
                </p>
              </div>
            </div>
            <div className="contactinformation-icon">
              <Link to={'https://www.facebook.com/glam9official'}>
                <i className="fa-brands fa-facebook"></i>
              </Link>

              <Link to={'https://www.instagram.com/glam9.beauty/'}>
                <i className="fa-brands fa-instagram"></i>
              </Link>

              <Link
                to={
                  'https://www.linkedin.com/company/glam9-solutions-private-limited/'
                }
              >
                <i className="fa-brands fa-linkedin"></i>
              </Link>

              <Link to={'https://x.com/glam9_beauty'}>
                <i className="fa-brands fa-x-twitter"></i>
              </Link>
            </div>
          </Grid>
          <Grid item xl={7} xs={12} md={12} lg={7} className="select-subject-cart">
            <h4>Select Subject?</h4>
            <form onSubmit={formik.handleSubmit} noValidate className="form1">
              {subjectSelection.map(subject => (
                <label
                  className={`supportRadio ${formik.values.subject === subject.key ? 'select' : ''}`}
                  key={subject.key}
                >
                  <input
                    type="radio"
                    name="subject"
                    value={subject.key}
                    onChange={() =>
                      formik.setFieldValue('subject', subject.key)
                    }
                    checked={formik.values.subject === subject.key}
                  />
                  <span
                    className={`supportRadioSpan ${
                      formik.values.subject === subject.key ? 'select' : ''
                    }`}
                  >
                    {formik.values.subject === subject.key && (
                      <svg
                        viewBox="0 0 24 24"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20.285 5.717a1 1 0 00-1.57-1.296L9.5 14.292l-3.215-3.215a1 1 0 00-1.415 1.414l4 4a1 1 0 001.415 0l10-10z" />
                      </svg>
                    )}
                  </span>
                  {subject.subject}
                </label>
              ))}

              {formik.touched.subject && formik.errors.subject && (
                <p className="error">{formik.errors.subject}</p>
              )}

              <div className="lader-cart-1">
                <div className="lader1">
                  <label htmlFor="firstName" id="lader1">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    {...formik.getFieldProps('firstName')}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <p className="error">{formik.errors.firstName}</p>
                  )}
                </div>
                <div className="lader2">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="name"
                    id="lastName"
                    {...formik.getFieldProps('lastName')}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <p className="error">{formik.errors.lastName}</p>
                  )}
                </div>
              </div>
              <div className="lader-cart-2">
                <div className="lader3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    {...formik.getFieldProps('email')}
                    pattern=".*" 
                    title=""
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="error">{formik.errors.email}</p>
                  )}
                </div>
                <div className="lader4">
                  <label htmlFor="phoneNumber">Phone Number</label>

                  <Box className="makeStyles-container-1">
                    <Box className='flagContainer'  /* className={classes.flagContainer} */ > 
                      <CustomPhoneInput
                        country={'in'}
                        preferredCountries={['in']} // Show India and USA at the top of the dropdown
                        enableSearch={false} // Enable the search feature in the dropdown
                        value={phone}
                        onChange={handleChange}
                        onlyCountries={['in']}
                        disableDropdown={true}
                        inputProps={{
                          style: { display: 'none' }, // Hide the default input
                        }}
                        /* containerClass={classes.flagImg} */
                        buttonStyle={{
                          position: 'static',
                          border: 'none',
                          textAlign: 'left',
                        }}
                        inputStyle={{
                          textAlign: 'left', // Align text to the left
                          paddingLeft: '10px', // Add padding to the left
                          paddingRight: '10px', // Optional, for balance
                        }}
                      />

                      <Typography className="countryCode">
                        {countryCode}
                      </Typography>
                    </Box>
                    <InputBase
                      type="tel"
                      id="phoneNumber"
                      value={formik.values.phoneNumber}
                      className="myClass"
                      aria-label="Your Phone Number"
                      onKeyDown={e => {
                        // Allow only numeric keys (0-9) and control keys (Backspace, Delete, Arrow keys, etc.)
                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            'Backspace',
                            'Delete',
                            'ArrowLeft',
                            'ArrowRight',
                          ].includes(e.key)
                        ) {
                          e.preventDefault(); // Prevent non-numeric characters
                        }
                      }}
                      onChange={e => {
                        const value = e.target.value.replace(/\D/g, ''); // Remove any non-numeric characters
                        // Ensure that the phone number is limited to 10 digits
                        if (value.length <= 10) {
                          formik.setFieldValue('phoneNumber', value); // Set the value only if it's <= 10 digits
                        }
                      }}
                    />
                  </Box>
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <p className="error">{formik.errors.phoneNumber}</p>
                  )}
                </div>
              </div>
              <div className="lader5">
                <label htmlFor="message">Message</label>

                <textarea
                  style={{
                    width: '100%',
                    background: 'none',
                    resize: 'none', // Disable manual resizing if needed
                    overflow: 'hidden', // Prevent scrollbar
                  }}
                  id="message"
                  {...formik.getFieldProps('message')}
                  placeholder="Write your message.."
                  
                ></textarea>
                {formik.touched.message && formik.errors.message && (
                  <p className="error">{formik.errors.message}</p>
                )}
              </div>
              <div className='last'>
              {loading ? (
                  <></>
                ) : (
                  <p>{showMessage}</p>
                )}
                
              <button type="submit" className="btn" disabled={loading}>
              Send Inquiry {loading ? (
                  <Spinner />
                ) : (
                  ""
                )}
              </button>
              </div>    
            </form>
          </Grid>
          </Grid> 
        </div>
      </div>
    
  );
};

export default Support;
