import React from 'react';
import { Box, Avatar, Typography, Paper,Grid } from '@mui/material';
import './team.css';
import { images } from '../../../Assets/images';

interface TeamProps {
  TeamMemberProps:any
};

const TeamMember: React.FC<TeamProps> = ({ TeamMemberProps }) => {
  return (
    <>
    
    {TeamMemberProps.data && TeamMemberProps.data.length > 0 && (
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            wrap="wrap"
            sx={{ marginTop: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                className="services"
                sx={{ marginRight: 2 }}
              >
                Our Team
              </Typography>
            </Grid>
            {TeamMemberProps?.data &&
              TeamMemberProps.data.length > 0 ? (
                TeamMemberProps?.data.map((item:any, index:any) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box className="team" >
                    {/* Profile Image */}
                    <Box
                      component="img"
                      src={
                        item?.profile_image
                          ? item?.profile_image
                          : images?.glam9512
                      }
                      onError={e => {
                        const target = e.target as HTMLImageElement;
                        target.src = images?.glam9512;
                      }}
                    />
                    {/* Name */}
                    <Typography>
                      {item.first_name}{' '}
                      {item.last_name && item.last_name}
                    </Typography>
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography className="teamNotFound">No team members found.</Typography>
            )}
          </Grid>
        </Grid>
      )}
      </>
  );
};

export default TeamMember;
