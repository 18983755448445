import { CustomerAddressReqInterface } from '../../src/api/types/customer';
import { addCustomerAddress, getCustomerProfile, updateCustomerProfile, getSubscriptionPlans } from '../../src/api/customer';
export const CustomerAPI = () => {
  const addCustomerAddressAPICall = async (data: CustomerAddressReqInterface): Promise<any> => {
    try {
      const response: any = await addCustomerAddress(data);

      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as any;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as any;
    }
  };
  
  const getCustomerProfileAPICall = async (): Promise<any> => {
    try {
      const response: any = await getCustomerProfile();
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as any;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as any;
    }
  };
  const updateCustomerProfileAPICall = async (data: any): Promise<any> => {
    try {
      const response: any = await updateCustomerProfile(data);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as any;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as any;
    }
  };


  const getSubscriptionPlansAPICall = async (queryParams: string | number): Promise<any> => {
    try {
      const response: any = await getSubscriptionPlans(queryParams);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as any;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as any;
    }
  };

  return { addCustomerAddressAPICall, getCustomerProfileAPICall, updateCustomerProfileAPICall, getSubscriptionPlansAPICall };
};
