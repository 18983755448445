import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './DeleteAccountSection.css';

const DeleteAccountSection: React.FC = () => {
  return (
    <Box className="deleteAccountSection">
      <Box className="textContainer">
        <Typography variant="h3" className="heading">
          Delete Account
        </Typography>
        <Typography variant="body1" className="description">
          Are you sure you want to leave Glam9?
        </Typography>
      </Box>
      <Button variant="outlined" className="deleteButton">
        Delete My Account
      </Button>
    </Box>
  );
};

export default DeleteAccountSection;
